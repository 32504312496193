
import { computed, defineComponent, ref, Ref, watch } from "vue";
import { userValidator } from "@/services/administrator/administratorValidators/userValidator";
import { EntityResponse } from "@/services/administrator/api/managementEntityService";
import { UserResponse } from "@/services/administrator/api/managementUserService";
import { managementUserActions } from "@/uses/administrator/managementUserActions";

export default defineComponent({
  name: "CreateEditUser",
  props: {
    actionLabel: {
      type: String,
      default: "CREAR",
    },
    user: {
      type: Object as () => UserResponse,
      required: false,
    },
    entityName: {
      type: String,
      default: "",
    },
    entityId: {
      type: String,
      default: "",
    }
  },
  components: {},
  setup(props, context) {
    async function closeModal() {
      await goToUsers(props.entityId, props.entityName);
      context.emit("close", false);
    }
    const { name, lastname, email, profile, cellPhone, phone, successForm } =
      userValidator();

    const { createUser, updateUser, goToUsers } = managementUserActions();
    fullForm()

    watch(
      () => props.user,
      () => {
        fullForm();
      }
    );

    function fullForm() {
      name.value.val = props.user?.firstName || "";
      lastname.value.val = props.user?.lastName || "";
      email.value.val = props.user?.email || "";
      profile.value.val = props.user?.profile || "";
      cellPhone.value.val = props.user?.mobilePhone || "";
      phone.value.val = props.user?.localPhone || "";
    }

    async function resetValidator() {
      name.value.val = "";
      lastname.value.val = "";
      email.value.val = "";
      profile.value.val = "";
      cellPhone.value.val = "";
      phone.value.val = "";
      await new Promise(r => setTimeout(r, 100));
      name.value.enabled = false;
      lastname.value.enabled = false;
      email.value.enabled = false;
      profile.value.enabled = false;
      cellPhone.value.enabled = false;
      phone.value.enabled = false;
    }

    async function createEditUserTransaction() {
      try {
        if (props.user?.id) {
          await updateUser(
              props.user.id,
              name.value.val || "",
              lastname.value.val || "",
              profile.value.val || "",
              cellPhone.value.val || "",
              phone.value.val || "",
          )
        } else {
          await createUser(
              name.value.val || "",
              lastname.value.val || "",
              email.value.val || "",
              profile.value.val || "",
              cellPhone.value.val || "",
              phone.value.val || "",
              2,
              props.entityId,
              props.entityName,
          )
        }
        await resetValidator();
        context.emit("close", true);
      } catch (e) {
        await resetValidator();
        context.emit("close", false);
        throw e;
      }
    }

    const emailEnabled = computed(() => {
      return props.user?.id;
    });

    return {
      name,
      lastname,
      email,
      profile,
      cellPhone,
      phone,
      successForm,
      createEditUserTransaction,
      closeModal,
      emailEnabled,
    };
  },
});
