import { useRouter } from "vue-router";
import { sessionStore } from "@/store/sessionStore";
import {
    CreateCognitoRequest,
    managementAuthenticationService
} from "@/services/authentication/api/managementAuthenticationService";
import {onMounted, ref, Ref} from "vue";
import { timeStore } from "@/store/timeStore";

export function definePasswordAction() {

    const router = useRouter();
    const showSuccessfulUploadModal: Ref<boolean> = ref(false);
    const domainRoute: Ref<string> = ref("");
    const { setInitialDate } = timeStore();

    onMounted(async () => {
        domainRoute.value = await router.currentRoute.value.meta.domainRoute as string

    })

    async function definePassword(
        password: string
    ): Promise<void> {
        const confirmLoginRequest: CreateCognitoRequest = {
            email: sessionStore().getEmail(),
            password: password
        };
        await managementAuthenticationService().createCognitoUser(confirmLoginRequest);
        setInitialDate(new Date());
        showSuccessfulModal();
    }

    function showSuccessfulModal(): void {
        showSuccessfulUploadModal.value = true;
    }

    async function closeSuccessfulModal(): Promise<void> {
        showSuccessfulUploadModal.value = false;
        await router.push(domainRoute.value.concat("login"));
    }

    async function returnLogin() {;
        await router.push(domainRoute.value.concat("login"));
      }      

    return {
        definePassword,
        closeSuccessfulModal,
        showSuccessfulUploadModal,
        returnLogin,
    };
}