
import {computed, defineComponent, onMounted, ref, Ref} from "vue";
import { profileValidator } from "@/services/entity/entityValidators/profileValidator";
import SuccessfulModal from "@/modals/SuccessfulModal.vue";
import { profileActions } from "@/uses/entity/profileActions";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "Profile",
  components: {
    SuccessfulModal,
    Header,
  },
  setup(props, context) {
    const image: Ref<string> = ref("")
    const { name, lastname, cellPhone, phone, email, successForm } =
      profileValidator();

    const { showSuccessful, closeSuccessfulModal, user, getEmail, updateUser, getEntityImage } =
      profileActions();

    name.value.val = user.value.firstName;
    lastname.value.val = user.value.lastName;
    cellPhone.value.val = user.value.mobilePhone;
    phone.value.val = user.value.localPhone;
    email.value.val = getEmail();

    onMounted(() => {
      image.value = getEntityImage();
    })

    async function profileTransaction() {
      await updateUser(
        user.value.id,
        name.value.val ?? "",
        lastname.value.val ?? "",
        user.value.profile ?? "",
        cellPhone.value.val ?? "",
        phone.value.val ?? ""
      );
    }

    return {
      name,
      lastname,
      cellPhone,
      phone,
      email,
      successForm,
      showSuccessful,
      closeSuccessfulModal,
      profileTransaction,
      user,
      image,
    };
  },
});
