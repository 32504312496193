import { userStore } from "@/store/userStore";
import { useRouter } from "vue-router";
import { sessionStore } from "@/store/sessionStore";
import {
  managementLoginService,
  ConfirmLoginRequest,
} from "@/services/authentication/api/managementLoginService";
import { onMounted, ref, Ref } from "vue";

export function confirmLoginActions() {
    const tries: Ref<number> = ref(0);
    const router = useRouter();
    const domainRoute: Ref<string> = ref("");

    onMounted(async () => {
        domainRoute.value = (await router.currentRoute.value.meta.domainRoute) as string;
    });

    async function confirmLogin(
        code: string
    ): Promise<void> {
        const confirmLoginRequest: ConfirmLoginRequest = {
            email: sessionStore().getEmail(),
            session: sessionStore().getSession(),
            code: code
        };
        const response = await managementLoginService().confirmLogin(confirmLoginRequest);
        if (response.error) {
            tries.value = tries.value + 1;
            if (tries.value >= 3) {
                tries.value = 0;
                await router.push(domainRoute.value.concat("login"));
            }
            throw new Error("Los datos ingresados son incorrectos.");
        } else {
            await userStore().setToken(response.accessToken);
            const user = await managementLoginService().getUser(userStore().getToken());
            await userStore().setUser(user);
            await goToDestiny();
        }
    }

    async function goToDestiny() {
        const destiny = domainRoute.value == "/user/" ? domainRoute.value.concat("upload-sample") : domainRoute.value.concat("main-menu")
        await router.push(destiny);
    }

    async function returnLogin(){
        await router.push(domainRoute.value.concat("login"));
    }

    return {
        confirmLogin,
        returnLogin,
    }
}
