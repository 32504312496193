
import { defineComponent, watch } from "vue";
import { baseSampleDetailActions } from "@/uses/administrator/managementBaseSampleDetailActions";
import {EntityAnalysisResponse} from "@/services/administrator/api/managementAdministratorService";

export default defineComponent({
  name: "DetailsSampleModal",
  props: {
    id: {
      type: String,
      default: "",
      required: true,
    },
    sampleBasic: {
      type: Object as () => EntityAnalysisResponse,
    },
  },
  setup(props, context) {
    const { sample, getSampleDetails } = baseSampleDetailActions();

    function closeModal() {
      context.emit("close");
    }

    watch(
      () => props.id,
      () => {
        getSampleDetails(props.id);
      }
    );

    watch(
        () => props.sampleBasic,
        () => {
        }
    );

    return {
      closeModal,
      sample,
    };
  },
});
