
import { computed, defineComponent, ref, Ref } from "vue";
import { graphicActions } from "@/uses/administrator/graphicActions";
import { baseStatisticsActions } from "@/uses/administrator/managementBaseStatisticsActions";
import Paginator from "@/components/Paginator.vue";
import { paginator } from "@/uses/administrator/paginator";
import DetailsSampleModal from "@/modals/DetailsSampleModal.vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "Statistics",
  components: {
    Paginator,
    DetailsSampleModal,
    Header,
  },
  setup() {
    const {
      statistics,
      graphicsOption,
      showDetails,
      showDetail,
      sampleId,
      closeDetails,
      selectedSample,
    } = baseStatisticsActions();
    const {
      isFirstPage,
      isLastPage,
      nextPage,
      previousPage,
      returnData,
      maxItems,
    } = paginator();

    maxItems.value = 7;

    const paginateStatistics = computed(() => {
      return returnData(statistics.value);
    });

    return {
      graphicsOption,
      isFirstPage,
      isLastPage,
      nextPage,
      previousPage,
      statistics,
      paginateStatistics,
      showDetails,
      showDetail,
      sampleId,
      closeDetails,
      selectedSample,
    };
  },
});
