
import { computed, defineComponent, ref, Ref } from "vue";

export default defineComponent({
  name: "SuccessfulModal",
  props: {
    message: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props, context) {
    function closeModal() {
      context.emit("close");
    }

    return {
      closeModal,
    };
  },
});
