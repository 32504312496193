import { useRouter } from "vue-router";
import { sessionStore } from "@/store/sessionStore";
import {
    managementAuthenticationService, VerifyMFARequest,
} from "@/services/authentication/api/managementAuthenticationService";
import { onMounted, ref, Ref } from "vue";

export function verifyMFAAction() {

    const router = useRouter();
    const QR_MFA: Ref<string> = ref("");
    const tries: Ref<number> = ref(0);
    const showSuccessfulUploadModal: Ref<boolean> = ref(false);
    const domainRoute: Ref<string> = ref("");

    onMounted(async () => {
        domainRoute.value = await router.currentRoute.value.meta.domainRoute as string
        const qr_value = "otpauth://totp/ColCERT%20An%C3%A1lisis%20Malware?secret=" + sessionStore().getSecretCode();
        QR_MFA.value = 'https://chart.googleapis.com/chart?cht=qr&chs=' + 400 + 'x' + 400 + "&chl=" +
            encodeURIComponent(qr_value);
    });

    async function verifyMFA(
        code: string
    ): Promise<void> {
        const verifyMFARequest: VerifyMFARequest = {
            email: sessionStore().getEmail(),
            session: sessionStore().getSession(),
            code: code
        };
        const response = await managementAuthenticationService().verifyMFA(verifyMFARequest);
        if (response.error) {
            tries.value = tries.value + 1;
            if (tries.value >= 3) {
                tries.value = 0;
                await router.push(domainRoute.value.concat("login"));
            }
            throw new Error("Los datos ingresados son incorrectos.");
        }
        else {
            showSuccessfulModal();
        }
    }

    function showSuccessfulModal(): void {
        showSuccessfulUploadModal.value = true;
    }

    async function closeSuccessfulModal(): Promise<void> {
        showSuccessfulUploadModal.value = false;
        await router.push(domainRoute.value.concat("login"));
    }

    async function returnLogin() {;
        await router.push(domainRoute.value.concat("login"));
      }   

    return {
        verifyMFA,
        QR_MFA,
        closeSuccessfulModal,
        showSuccessfulUploadModal,
        returnLogin,
    };
}