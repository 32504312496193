import { ContractRule } from "@/services/commons/validator/validatorsTypes";

export class NumericRule implements ContractRule {
  validationMessage: string;

  constructor(validationMessage: string) {
    this.validationMessage = validationMessage;
  }

  check(value: string, otherValue?: string): boolean {
    return !isNaN(Number(value));
  }
}
