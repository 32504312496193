import { onMounted, ref, Ref } from "vue";
import { userStore } from "@/store/userStore";
import {
    EntityAnalysisResponse,
    managementAdministratorService
} from "@/services/administrator/api/managementAdministratorService";
import {useRouter} from "vue-router";

export function baseStatisticsActions() {

    const statistics: Ref<EntityAnalysisResponse[]> = ref([]);
    const router = useRouter();
    const showDetail: Ref<boolean> = ref(false)
    const sampleId: Ref<string> = ref("")
    const selectedSample: Ref<EntityAnalysisResponse | null> = ref(null)

    onMounted(async () => {
        statistics.value = await managementAdministratorService().allSamplesEntity(userStore().getToken());
    });

    async function graphicsOption() {
        await router.push("/administrator/graphics");
    }

    function showDetails(sample: EntityAnalysisResponse) : void {
        sampleId.value = sample.sampleId;
        selectedSample.value = sample;
        showDetail.value = true;

    }

    function closeDetails() : void {
        showDetail.value = false
    }

    return {
        statistics,
        graphicsOption,
        showDetails,
        showDetail,
        sampleId,
        closeDetails,
        selectedSample,
    }
}