import { userStore } from "@/store/userStore";
import { onMounted, ref, Ref } from "vue";
import {
    managementAdministratorService, SampleResponse
} from "@/services/administrator/api/managementAdministratorService";

const emptySample: SampleResponse = {
    id: "",
    fileName: null,
    url: null,
    type: "",
    state: "",
    hashMD5: null,
    size: null,
    result: null,
    isMalicious: null,
    response: null,
    date: ""
};

export function baseSampleDetailActions() {

    const sample: Ref<SampleResponse> = ref(emptySample);
    async function getSampleDetails(id: string): Promise<void> {
        sample.value = await managementAdministratorService().getSample(id, userStore().getToken());
        sample.value.type = sample.value.type == "File" ? "Archivo" : sample.value.type;
    }

    return {
        sample,
        getSampleDetails,
    }

}