import {
    change_password, forgot_password, reset_password
} from "@/services/authentication/api/authenticationUrls";
import { RestResponse } from "@/services/commons/rest/RestResponse";
import { sendRequest } from "@/services/commons/rest/restClient";

export interface ChangePasswordRequest {
    newPassword: string;
    oldPassword: string;
}

export interface ForgotPasswordRequest {
    email: string;
}

export interface ResetPasswordRequest {
    email: string;
    code: string;
    newPassword: string;
}

export function managementPasswordService() {
    async function changePassword(request: ChangePasswordRequest, accessToken: string): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(change_password);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "POST", JSON.stringify(request), jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La contraseña ingresada es incorrecta.",
                    code: response.statusCode
                }
            }
            else {
                const message = JSON.parse(JSON.stringify(response.content))
                throw {
                    message: message.error,
                    code: response.statusCode
                }
            }
        }
    }

    async function forgotPassword(request: ForgotPasswordRequest): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(forgot_password);
        const response: RestResponse = await sendRequest(url, "POST", JSON.stringify(request), null);

        if (!response.isSuccess) {
            if (response.statusCode == 404) {
                throw {
                    message: "El usuario no existe.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
    }

    async function resetPassword(request: ResetPasswordRequest): Promise<boolean> {
        const url = process.env.VUE_APP_BASE_URL.concat(reset_password);
        const response: RestResponse = await sendRequest(url, "POST", JSON.stringify(request), null);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                return true;
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
        return false;
    }


    return {
        changePassword,
        forgotPassword,
        resetPassword,
    }
}