import { ref, Ref } from "vue";

export interface ExpirationTime {
  exigencyTime: number;
  initialDate: Date | null;
}

const initialObject = {
  initialDate: null,
  exigencyTime: process.env.VUE_APP_WAITING_CODE_MFA_TIME,
};

const time: Ref<ExpirationTime> = ref(initialObject);

export function timeStore() {
  function getExigencyTime(): number {
    return time.value.exigencyTime;
  }

  function setInitialDate(date: Date): void {
    time.value.initialDate = date;
  }

  function resetDate(): void {
    time.value = initialObject;
  }

  function isTimeExpired(date: Date): boolean {
    if (time.value.initialDate == null) {
      return true;
    }

    const currentDate = new Date();
    const diff: Ref<number> = ref(
      (date.getTime() -
        (time.value.initialDate?.getTime() || currentDate.getTime())) /
        1000
    );
    diff.value /= 60;
    return diff.value >= time.value.exigencyTime;
  }

  return {
    setInitialDate,
    getExigencyTime,
    isTimeExpired,
    resetDate,
  };
}
