import { onMounted, ref, Ref } from "vue";
import { userStore } from "@/store/userStore";
import { managementUserService, UserResponse } from "@/services/administrator/api/managementUserService";
import { useRouter } from "vue-router";
import {
    managementParametersService,
    UpdateParameter
} from "@/services/administrator/api/managementParametersService";

export function baseParametersActions() {

    const router = useRouter();
    const modalCreateEditParameter: Ref<boolean> = ref(false);
    const successfulModal: Ref<boolean> = ref(false);
    const messageSuccess: Ref<string> = ref("");
    const actualParameter: Ref<UpdateParameter | null> = ref(null);
    const parameters: Ref<UpdateParameter[]> = ref([])

    onMounted(async () => {
         parameters.value = await managementParametersService().allParameters(userStore().getToken());
    })

    function showCreateEditParameter() {
        modalCreateEditParameter.value = true;
    }

    function modifyActualParameter(parameter: UpdateParameter) : void {
        actualParameter.value = parameter;
        showCreateEditParameter();
    }

    function selectedItemEdit() {
        modalCreateEditParameter.value = true;
    }    

    function showSuccessfulModal() {
        successfulModal.value = true;
    }

    function closeCreateEditParameter(wasSuccessful: boolean) {
        modalCreateEditParameter.value = false;
        if (wasSuccessful) {
            showSuccessfulModal();
            messageSuccess.value = `Operación exitosa al editar el parámetro ${actualParameter.value?.key.toLowerCase()}`
        }
    }

    async function closeSuccessfulModal() {
        successfulModal.value = false;
        await router.push('/')
        await router.push(`/administrator/parameters`);
    }


    return {
        modalCreateEditParameter,
        showCreateEditParameter,
        selectedItemEdit,
        showSuccessfulModal,
        successfulModal,
        messageSuccess,
        actualParameter,
        modifyActualParameter,
        parameters,
        closeCreateEditParameter,
        closeSuccessfulModal,
    }
}