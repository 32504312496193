import {
    general_user,
    find_by_entity, parameters, general_entity,
} from "@/services/administrator/api/adminUrls";
import { RestResponse } from "@/services/commons/rest/RestResponse";
import { sendRequest } from "@/services/commons/rest/restClient";
import {EntityResponse} from "@/services/administrator/api/managementEntityService";

export interface UpdateParameter {
    key: string;
    value: string;
}

export function managementParametersService() {
    async function updateParameter(request: UpdateParameter, accessToken: string): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(parameters);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "PUT", JSON.stringify(request), jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else {
                const message = JSON.parse(JSON.stringify(response.content))
                throw {
                    message: message.error,
                    code: response.statusCode
                }
            }
        }
    }

    async function allParameters(accessToken: string): Promise<UpdateParameter[]> {
        const url = process.env.VUE_APP_BASE_URL.concat(parameters);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "GET", null, jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
        return JSON.parse(JSON.stringify(response.content));
    }

    return {
        updateParameter,
        allParameters,
    }
}