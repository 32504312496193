import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { PasswordRule } from "@/services/commons/validator/rules/PasswordRule";
import { validator } from "@/services/commons/validator/validator";

export function passwordValidator() {
  const password: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("La contraseña es obligatoria"),
      new PasswordRule("La contraseña debe contener como mínimo: 1 mayúscula, 1 minúscula, 1 número, 1 símbolo y 8 caracteres."),
    ],
  });

  watch(
    () => password.value.val,
    () => {
      password.value.enabled = true;
    }
  );

  function validate(field: GenericField): GenericField {
    return validator().validateField(field);
  }

  const successForm = computed(() => {
    password.value = validate(password.value);
    return !(password.value.isSuccess);
  });

  return {
    password,
    successPasswordForm: successForm,
  };
}
