import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { validator } from "@/services/commons/validator/validator";

export function uploadFileValidator() {
  const fileName: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El archivo es obligatorio"),
    ],
  });

  watch(
    () => fileName.value.val,
    () => {
      fileName.value.enabled = true;
    }
  );

  function validate(field: GenericField): GenericField {
    return validator().validateField(field);
  }

  const successForm = computed(() => {
    fileName.value = validate(fileName.value);
    return !(fileName.value.isSuccess);
  });

  return {
    fileName,
    successFile: successForm,
  };
}
