
import {computed, defineComponent, onMounted, ref, Ref, watch} from "vue";
import SuccessfulUpload from "@/modals/SuccessfulUpload.vue";
import { uploadSampleActions } from "@/uses/entity/uploadSampleActions";
import { uploadSampleValidator } from "@/services/entity/entityValidators/uploadSampleValidator";
import { uploadUrlValidator } from "@/services/user/externalUserValidators/uploadUrlValidator";
import { uploadFileValidator } from "@/services/user/externalUserValidators/uploadFileValidator";
import { VueRecaptcha } from "vue-recaptcha";
import TermsAndConditions from "@/modals/TermsAndConditions.vue";
import Spinner from "@/components/Spinner.vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "UploadSample",
  components: {
    SuccessfulUpload,
    TermsAndConditions,
    VueRecaptcha,
    Spinner,
    Header,
  },
  setup(props, context) {
    const showSuccessfulUploadModal: Ref<boolean> = ref(false);
    const {
      urlOption,
      fileOption,
      selectSampleOption,
      showIconFile,
      disabledUrlBox,
      termsAndConditions,
      uploadSample,
      resetForm,
      validateCaptcha,
      verifyCaptcha,
      captchaKey,
      expiredCaptcha,
      modalTermsAndConditions,
      showTermsAndConditions,
      closeTermsAndConditions,
      getEntityImage,
      user,
    } = uploadSampleActions();
    const { incidentNumber, incidentContext, successForm } =
      uploadSampleValidator();
    const { successURL, url } = uploadUrlValidator();
    const { successFile, fileName } = uploadFileValidator();
    const fileSample = ref(null);
    const file = ref(null);
    const image: Ref<string> = ref("")
    const showSpinner : Ref<boolean> = ref(false)

    onMounted(() => {
      image.value = getEntityImage();
    })

    const fullValidator = computed(() => {
      const initialValidation =
        successForm.value ||
        !termsAndConditions.value
          || !validateCaptcha.value;
      return urlOption.value
        ? initialValidation || successURL.value
        : initialValidation || successFile.value;
    });

    function loadFile(event: any) {
      const fileUploaded = event.target.files[0];
      fileName.value.val = fileUploaded.name;
      file.value = fileUploaded;
      url.value.val = null;
    }

    async function uploadSampleTransaction() {
      showSpinner.value = true;
      await uploadSample(
        incidentNumber.value.val || "",
        incidentContext.value.val || "",
        url.value.val,
        file.value
      );
      await new Promise(r => setTimeout(r, 700));
      showSpinner.value = false;
      showSuccessfulUploadModal.value = true;
    }

    function closeModal() {
      showSuccessfulUploadModal.value = false;
      resetForm();

    }

    watch(
        () => urlOption.value,
        () => {
          url.value.val = urlOption.value ? url.value.val : "";
        }
    );

    watch(
        () => fileOption.value,
        () => {
          fileName.value.val = fileOption.value ? fileName.value.val : "";
          file.value = null;
        }
    );

    return {
      showSuccessfulUploadModal,
      urlOption,
      fileOption,
      selectSampleOption,
      showIconFile,
      disabledUrlBox,
      incidentNumber,
      incidentContext,
      url,
      fileName,
      successForm,
      termsAndConditions,
      fullValidator,
      successURL,
      successFile,
      fileSample,
      loadFile,
      closeModal,
      uploadSampleTransaction,
      verifyCaptcha,
      captchaKey,
      expiredCaptcha,
      modalTermsAndConditions,
      showTermsAndConditions,
      closeTermsAndConditions,
      user,
      showSpinner,
      image,
    };
  },
});
