import { Ref, ref } from "vue";
import {encryptStorage} from "@/services/commons/security/Encrypt";

export interface UserSession {
    session: string,
    secretCode: string | null,
    email: string,
}

const emptyUserSession: UserSession = {
    session: "",
    secretCode: null,
    email: ""
}

const userSession: Ref<UserSession> = ref(emptyUserSession);

export function sessionStore() {

    function loadSessionMemory(): void {
        const loadSession = encryptStorage.getItem("session");
        if (loadSession && loadSession != null && loadSession != undefined && loadSession != "") {
            userSession.value = loadSession;
        }
    }

    async function persistSession(): Promise<void> {
        encryptStorage.setItem("session", JSON.stringify(userSession.value));
    }

    async function logout() {
        userSession.value.session = "";
        userSession.value.secretCode = null;
        userSession.value.email = "";
        encryptStorage.removeItem("session");
    }

    async function setSession(session: string) {
        userSession.value.session = session;
        await persistSession();
    }

    async function setSecretCode(secretCode: string | null) {
        userSession.value.secretCode = secretCode;
        await persistSession();
    }

    async function setEmail(email: string) {
        userSession.value.email = email;
        await persistSession();
    }

    function getSession(): string {
        return userSession.value.session;
    }

    function getSecretCode(): string | null {
        return userSession.value.secretCode;
    }

    function getEmail(): string {
        return userSession.value.email;
    }

    function cleanSession(): void {

    }


    return {
        userSession,
        setSession,
        setSecretCode,
        setEmail,
        getSession,
        getSecretCode,
        getEmail,
        logout,
        loadSessionMemory,
    }
}