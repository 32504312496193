import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorModal = _resolveComponent("ErrorModal")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DeviceNotCompatible = _resolveComponent("DeviceNotCompatible")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_ErrorModal, {
      close: _ctx.hasError,
      "onUpdate:close": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hasError) = $event)),
      message: _ctx.message
    }, null, 8, ["close", "message"]), [
      [_vShow, _ctx.hasError]
    ]),
    _createVNode(_component_router_view),
    _createVNode(_component_DeviceNotCompatible, { class: "notCompatible" })
  ], 64))
}