import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { validator } from "@/services/commons/validator/validator";
import {LengthRule} from "@/services/commons/validator/rules/LengthRule";

export function uploadSampleValidator() {
  const incidentNumber: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El número de incidente es obligatorio"),
      new LengthRule("El número de incidente tiene un máximo de 2000 caracteres"),
    ],
  });

  const incidentContext: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El contexto y descripción de la muestra es obligatorio"),
      new LengthRule("El contexto y descripción tiene un máximo de 2000 caracteres"),
    ],
  });

  watch(
    () => incidentNumber.value.val,
    () => {
      incidentNumber.value.enabled = true;
    }
  );

  watch(
    () => incidentContext.value.val,
    () => {
      incidentContext.value.enabled = true;
    }
  );

  function validate(field: GenericField): GenericField {
    return validator().validateField(field);
  }

  const successForm = computed(() => {
    incidentNumber.value = validate(incidentNumber.value);
    incidentContext.value = validate(incidentContext.value);
    return !(incidentNumber.value.isSuccess && incidentContext.value.isSuccess);
  });

  return {
    incidentNumber,
    incidentContext,
    successForm,
  };
}
