import { ContractRule } from "@/services/commons/validator/validatorsTypes";

export class LengthRule implements ContractRule {
  validationMessage: string;

  constructor(validationMessage: string) {
    this.validationMessage = validationMessage;
  }

  check(value: string, otherValue?: string): boolean {
    const length = value.length >= 2000;
    return !length;
  }
}
