import {onMounted, ref, Ref} from "vue";
import { useRouter } from "vue-router";
import {
    EntityResponse,
    managementAdministratorService, UserResponse
} from "@/services/administrator/api/managementAdministratorService";
import {userStore} from "@/store/userStore";


export function graphicActions() {

    const router = useRouter();
    const entitiesTop5: Ref<EntityResponse[]> = ref([]);
    const usersTop5: Ref<UserResponse[]> = ref([]);
    const dataGraphicEntities: Ref<number[]> = ref([]);
    const dataLabelEntities: Ref<string[]> = ref([]);
    const dataGraphicUsers: Ref<number[]> = ref([]);
    const dataLabelUsers: Ref<string[]> = ref([]);
    const switchGraph: Ref<boolean> = ref(false)

    onMounted( async () => {
        entitiesTop5.value = await managementAdministratorService().top5Entities(userStore().getToken());
        usersTop5.value = await managementAdministratorService().top5Users(userStore().getToken());
        calculateDataGraphicEntities(entitiesTop5.value);
        calculateDataGraphicUsers(usersTop5.value);
    });

    function calculateDataGraphicEntities(data: EntityResponse[]) {
        dataGraphicEntities.value =  data.map(item => item.amountAnalysis);
        dataLabelEntities.value =  data.map(item => item.name);
    }

    function calculateDataGraphicUsers(data: UserResponse[]) {
        dataGraphicUsers.value =  data.map(item => item.amountAnalysis);
        dataLabelUsers.value =  data.map(item => item.name);
    }

    async function statisticsOption() {
        await router.push("/administrator/statistics");
    }



    function changeGraph() {
        switchGraph.value = !switchGraph.value
    }


    return {
        statisticsOption,
        dataGraphicEntities,
        dataLabelEntities,
        dataGraphicUsers,
        dataLabelUsers,
        changeGraph,
        switchGraph,
    };
}
