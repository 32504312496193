
import { computed, defineComponent, ref, Ref } from "vue";
import { entityFileValidator } from "@/services/administrator/administratorValidators/entityFileValidator";
import {createEntitiesByFileActions} from "@/uses/administrator/managementCreateEntitiesByFileActions";

export default defineComponent({
  name: "CreateEntityUploadFile",
  setup(props, context) {

    const { fileName, successForm } = entityFileValidator();
    const fileSample = ref(null);
    const file = ref(null);
    const { createEntitiesByFile } = createEntitiesByFileActions();

    async function loadFile(event: any) {

      const fileUploaded = event.target.files[0];

      if (fileUploaded != undefined) {
        fileName.value.val = fileUploaded.name;
        file.value = fileUploaded;

        const extension = fileName.value.val?.split('.').pop() ?? "";
        if (!extension.includes("xlsx")) {
          await resetValidator();
          await closeModal();
          throw {
            message: "Extensión no permitida",
            code: 400
          };
        }
      }
    }

    async function closeModal() {
      await resetValidator();
      context.emit("close", false);
    }

    async function createEntityFileTransaction() {
      context.emit("update:spinner", true);
      try {
        await createEntitiesByFile(file.value);
        await new Promise(r => setTimeout(r, 700));
        context.emit("close", true);
      }
      catch (e) {
        context.emit("close", false);
        throw e;
      } finally {
        context.emit("update:spinner", false);
        await resetValidator();
      }
    }

    async function resetValidator() {
      fileName.value.val = "";
      await new Promise(r => setTimeout(r, 100));
      fileName.value.enabled = false;
      file.value = null;
    }

    return {
      fileName,
      successForm,
      createEntityFileTransaction,
      closeModal,
      loadFile,
      fileSample,
    };
  },
});
