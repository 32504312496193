import { GenericField } from "@/services/commons/validator/validatorsTypes";

export function validator() {
  function validateField(
    field: GenericField,
    otherValue?: string | null
  ): GenericField {
    field.errors = field.rules
      .filter((rule) => !rule.check(field.val ?? "", otherValue))
      .map((rule) => {
        return rule.validationMessage
      });
    field.isSuccess = field.errors.length === 0;
    return field;
  }

  return {
    validateField,
  };
}
