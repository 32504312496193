
import { defineComponent } from "vue";

export default defineComponent({
  name: "Paginator",
  components: {},
  props: {
    isFirstPage: {
      type: Boolean,
      required: true,
    },
    isLastPage: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    function nextPage(): void {
      context.emit("nextPage");
    }

    function previousPage(): void {
      context.emit("previousPage");
    }

    return { nextPage, previousPage };
  },
});
