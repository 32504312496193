
import { computed, defineComponent, ref, Ref } from "vue";
import { headerActions } from "@/uses/user/headerActions";

export default defineComponent({
  name: "MenuProfile",

  setup() {
    const { closeSession, malwareAnalysis, profile, changePassword } =
      headerActions();

    return {
      closeSession,
      malwareAnalysis,
      profile,
      changePassword,
    };
  },
});
