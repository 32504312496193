
import { computed, defineComponent, ref, Ref } from "vue";

import SuccessfulUpload from "@/modals/SuccessfulUpload.vue";
import { uploadSampleValidator } from "@/services/user/externalUserValidators/uploadSampleValidator";
import { useRouter } from "vue-router";
import Header from "@/components/Header.vue";
import FormUploadSampleProfilePublic from "@/components/FormUploadSampleProfilePublic.vue";
import FormUploadSampleCitizenship from "@/components/FormUploadSampleCitizenship.vue";
import FormUploadSampleProfilePrivate from "@/components/FormUploadSampleProfilePrivate.vue";

export default defineComponent({
  name: "UploadSample",
  components: {
    FormUploadSampleProfilePrivate,
    FormUploadSampleCitizenship,
    FormUploadSampleProfilePublic,
    SuccessfulUpload,
    Header,
  },
  setup() {
    const formChosen: Ref<number> = ref(0);
    const showSuccessfulUploadModal: Ref<boolean> = ref(false);
    const { profile, successForm } = uploadSampleValidator();
    const router = useRouter();

    function showSuccessfulModal(): void {
      showSuccessfulUploadModal.value = true;
    }

    async function closeSuccessfulModal(): Promise<void> {
      showSuccessfulUploadModal.value = false;
      await router.push("/user/login");
      await router.push("/external-user/upload-sample");
    }

    function choiceProfile(): void {
      const options : { [key: string]:  number; } = {
        "Ciudadanía": 1,
        "Empresa Privada": 2,
        "Empresa Pública": 3
      };

      const profileValue : string = profile.value.val ?? "";
      formChosen.value = options.hasOwnProperty(profileValue) ? options[profileValue] : 0;
    }

    const showForms = computed(() => {
      return !successForm.value;
    });

    return {
      showForms,
      showSuccessfulUploadModal,
      choiceProfile,
      profile,
      successForm,
      showSuccessfulModal,
      closeSuccessfulModal,
      formChosen,
    };
  },
});
