<template>
  <main class="footer">
    <p class="text-footer">
      Powered by Trellix
    </p>
  </main>
</template>

<style scoped lang="scss">
@import "src/styles/mixins.scss";

@font-face {
  font-family: Earth;
  src: url("@/assets/fonts/earth.ttf");
}

.footer {
  background-color: #E6EFFD;
  align-items: center;
  justify-content: center;
  display: flex;
}

.text-footer {
  font-family: "Earth";
  color: #3D4B58;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
  padding: 1.5rem;

  @include responsive-panel(PC1) {
    font-size: 1rem;
  }
}
</style>