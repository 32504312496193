import { confirm_login, get_user, login_url, pre_login } from "@/services/authentication/api/authenticationUrls";
import { RestResponse } from "@/services/commons/rest/RestResponse";
import { sendRequest } from "@/services/commons/rest/restClient";

export interface PreLoginResponse {
    state: string;
}

export interface LoginResponse {
    session: string;
    type: string;
    secretCode: string | null;
}

export interface LoginRequest {
    email: string;
    password: string;
    type: number;
}

export interface ConfirmLoginResponse {
    accessToken: string;
    error: boolean,
}

export interface ConfirmLoginRequest {
    email: string;
    session: string;
    code: string;
}

export interface UserResponse {
    lastName: string,
    firstName: string,
    role: string,
    id: string,
    profile: string,
    entityId: string,
    amountAnalysis: number,
    mobilePhone: string,
    entityName: string,
    name: string,
    state: string,
    email: string,
    localPhone: string,
    userCognitoId: string,
}

export function managementLoginService() {

    async function preLogin(email: string): Promise<PreLoginResponse> {
        const url = process.env.VUE_APP_BASE_URL.concat(pre_login).concat(email);
        const response: RestResponse = await sendRequest(url, "GET", null, null);

        if (!response.isSuccess) {
            const message = JSON.parse(JSON.stringify(response.content));

            throw {
                message: message.error,
                code: response.statusCode
            }
        }

        return JSON.parse(JSON.stringify(response.content));
    }

    async function login(request: LoginRequest): Promise<LoginResponse> {
        const url = process.env.VUE_APP_BASE_URL.concat(login_url);

        const response: RestResponse = await sendRequest(url, "POST", JSON.stringify(request), null);



        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "Los datos ingresados son incorrectos.",
                    code: response.statusCode
                }
            }
            else if(response.statusCode == 406) {
                throw {
                    message: "El portal no corresponde al tipo de usuario.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }

        return JSON.parse(JSON.stringify(response.content));
    }

    async function confirmLogin(request: ConfirmLoginRequest): Promise<ConfirmLoginResponse> {
        const url = process.env.VUE_APP_BASE_URL.concat(confirm_login);
        const response: RestResponse = await sendRequest(url, "POST", JSON.stringify(request), null);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                return {
                    accessToken: "",
                    error: true,
                };
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }

        return JSON.parse(JSON.stringify(response.content));
    }

    async function getUser(accessToken: string): Promise<UserResponse> {
        const url = process.env.VUE_APP_BASE_URL.concat(get_user);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "GET", null, jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
        return JSON.parse(JSON.stringify(response.content));
    }


    return {
        preLogin,
        login,
        confirmLogin,
        getUser,
    }
}

