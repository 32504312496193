import {
    general_user,
    find_by_entity,
} from "@/services/administrator/api/adminUrls";
import { RestResponse } from "@/services/commons/rest/RestResponse";
import { sendRequest } from "@/services/commons/rest/restClient";

export interface CreateUserRequest {
    firstName: string;
    lastName: string;
    email: string;
    profile: string;
    mobilePhone: string;
    localPhone: string;
    role: number;
    entityId: string;
    entityName: string;
}

export interface UpdateUserRequest {
    id: string,
    firstName: string;
    lastName: string;
    profile: string;
    mobilePhone: string;
    localPhone: string;
}

export interface UserResponse {
    lastName: string,
    firstName: string,
    role: string,
    id: string,
    profile: string,
    entityId: string,
    amountAnalysis: number,
    mobilePhone: string,
    entityName: string,
    name: string,
    state: string,
    email: string,
    localPhone: string,
    userCognitoId: string,
}

export function managementUserService() {
    async function createUser(request: CreateUserRequest, accessToken: string): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(general_user);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "POST", JSON.stringify(request), jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else if (response.statusCode == 404) {
                throw {
                    message: "El usuario ya existe.",
                    code: response.statusCode
                }
            }
            else {
                const message = JSON.parse(JSON.stringify(response.content))
                throw {
                    message: message.error,
                    code: response.statusCode
                }
            }
        }
    }

    async function updateUser(request: UpdateUserRequest, accessToken: string): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(general_user);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "PUT", JSON.stringify(request), jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else if (response.statusCode == 404) {
                throw {
                    message: "El usuario no existe.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
    }

    async function deleteUser(userId: string, accessToken: string): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(general_user).concat(userId);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "DELETE", null, jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else if (response.statusCode == 404) {
                throw {
                    message: "El usuario no existe.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
    }

    async function findUsersByEntity(accessToken: string, entityId: string): Promise<UserResponse[]> {
        const url = process.env.VUE_APP_BASE_URL.concat(find_by_entity).concat(entityId);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "GET", null, jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
        return JSON.parse(JSON.stringify(response.content));
    }


    return {
        createUser,
        findUsersByEntity,
        updateUser,
        deleteUser,
    }
}