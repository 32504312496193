import Layout from "@/views/general/Layout.vue";
import LoginFull from "@/views/administrator/LoginFull.vue";
import RegisterPassword from "@/views/user/RegisterPassword.vue";
import AuthenticationFactor from "@/views/user/AuthenticationFactor.vue";
import CodeMFA from "@/views/user/CodeMFA.vue";
import ConfirmCode from "@/views/user/ConfirmCode.vue";
import RecoverPassword from "@/views/user/RecoverPassword.vue";
import MainMenu from "@/views/administrator/MainMenu.vue";
import Entity from "@/views/administrator/Entity.vue";
import Users from "@/views/administrator/Users.vue";
import Statistics from "@/views/administrator/Statistics.vue";
import Graphics from "@/views/administrator/Graphics.vue";
import Parameters from "@/views/administrator/Parameters.vue";
import {isAuthenticate} from "@/router/isAuthenticate";

export const administratorRoutes = [
  {
    path: "/administrator/",
    component: Layout,
    children: [
      {
        path: "main-menu",
        component: MainMenu,
        beforeEnter: isAuthenticate,
        meta: {
          domainRoute: "/administrator/"
        }
      },
      {
        path: "entity",
        component: Entity,
        beforeEnter: isAuthenticate,
        meta: {
          domainRoute: "/administrator/"
        }
      },
      {
        path: "users/:id/:name",
        component: Users,
        beforeEnter: isAuthenticate,
        meta: {
          domainRoute: "/administrator/"
        },
        props: true,
      },
      {
        path: "statistics",
        component: Statistics,
        beforeEnter: isAuthenticate,
        meta: {
          domainRoute: "/administrator/"
        }
      },
      {
        path: "graphics",
        component: Graphics,
        beforeEnter: isAuthenticate,
        meta: {
          domainRoute: "/administrator/"
        }
      },
      {
        path: "parameters",
        component: Parameters,
        beforeEnter: isAuthenticate,
        meta: {
          domainRoute: "/administrator/"
        }
      },      
    ],
  },
];
