import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e255c94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-graphic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BarChart, {
      ref: "barRef",
      chartData: _ctx.dataGraph,
      options: _ctx.options,
      width: "1600",
      height: "500"
    }, null, 8, ["chartData", "options"])
  ]))
}