
import { computed, defineComponent, ref, Ref } from "vue";
import { authenticationFactorValidator } from "@/services/authentication/authenticationValidators/authenticationFactorValidator";
import { verifyMFAAction } from "@/uses/authentication/managementVerifyMFAActions";
import SuccessfulModal from "@/modals/SuccessfulModal.vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "AuthenticationFactor",
  components: {
    SuccessfulModal,
    Header
  },
  setup() {
    const { code, successForm } = authenticationFactorValidator();
    const {
      verifyMFA,
      QR_MFA,
      closeSuccessfulModal,
      showSuccessfulUploadModal,
      returnLogin,
    } = verifyMFAAction();

    return {
      code,
      successForm,
      QR_MFA,
      verifyMFA,
      closeSuccessfulModal,
      showSuccessfulUploadModal,
      returnLogin,
    };
  },
});
