import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { validator } from "@/services/commons/validator/validator";
import {LengthRule} from "@/services/commons/validator/rules/LengthRule";

export function uploadUrlValidator() {
  const url: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("La url es obligatoria"),
      new LengthRule("La url tiene un máximo de 2000 caracteres"),
    ],
  });

  watch(
    () => url.value.val,
    () => {
      url.value.enabled = true;
    }
  );

  function validate(field: GenericField): GenericField {
    return validator().validateField(field);
  }

  const successForm = computed(() => {
    url.value = validate(url.value);
    return !(url.value.isSuccess);
  });

  return {
    url,
    successURL: successForm,
  };
}
