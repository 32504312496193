
import { computed, defineComponent, ref, Ref } from "vue";
import CloseSesion from "@/components/CloseSession.vue";
import Menu from "@/components/Menu.vue";
import MenuProfile from "@/components/MenuProfile.vue";
import {headerActions} from "@/uses/administrator/headerActions";
import {headerOptionsActions} from "@/uses/headerOptions/headerOptionsActions";

export default defineComponent({
  name: "Header",
  components: {
    CloseSesion,
    Menu,
    MenuProfile,
  },
  props: {
    entityImage: {
      type: String,
      default: ""
    }
  },
  setup() {

    const { isAdmin, isUser, closeSessionAdmin, showMenuMainMenu } = headerActions();
    const { externalUserPortal, entityUserPortal, administratorPortal, isNotVisibleOptions } = headerOptionsActions();

    return {
      isAdmin,
      closeSessionAdmin,
      isUser,
      showMenuMainMenu,
      externalUserPortal,
      entityUserPortal,
      administratorPortal,
      isNotVisibleOptions,
    };
  },
});
