
import { computed, defineComponent, ref, Ref } from "vue";
import { emailValidator } from "@/services/authentication/authenticationValidators/emailValidator";
import { passwordValidator } from "@/services/authentication/authenticationValidators/passwordValidator";
import { loginActions } from "@/uses/authentication/managementLoginActions";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "LoginFull",
  components: {
    Header
  },
  setup() {
    const { email, successEmailForm } = emailValidator();
    const { password, successPasswordForm } = passwordValidator();
    const {
      showPassword,
      buttonText,
      preLogin,
      login,
      returnEmail,
      forgotPassword,
    } = loginActions();

    async function loginTransaction(): Promise<void> {
      await login(email.value.val ?? "", password.value.val ?? "", 1)
    }

    const chooseValidation = computed(() => {
      if (showPassword.value == true) {
        return successEmailForm.value || successPasswordForm.value;
      } else {
        return successEmailForm.value;
      }
    });

    async function returnLoginTransaction() {
      password.value.val = "";
      await new Promise(r => setTimeout(r, 100));
      password.value.enabled = false;
      returnEmail();
    }

    return {
      chooseValidation,
      preLogin,
      loginTransaction,
      returnEmail,
      forgotPassword,
      buttonText,
      showPassword,
      email,
      password,
      successEmailForm,
      successPasswordForm,
      returnLoginTransaction,
    };
  },
});
