import { ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { userStore } from "@/store/userStore";
import {sessionStore} from "@/store/sessionStore";

export function headerActions() {

    const router = useRouter();

    async function closeSession() {
        await userStore().closeSession();
        await sessionStore().logout();
        await router.push("/external-user/upload-sample");
    }

    async function malwareAnalysis() {
        await router.push("/user/upload-sample");
    }

    async function profile() {
        await router.push("/user/profile");
    }

    async function changePassword() {
        await router.push("/user/change-password");
    }

    async function externalUserPortal() {
        await router.push("/external-user/upload-sample");
    }    

    async function entityUserPortal() {
        await router.push("/user/login");
    }      

    async function administratorPortal() {
        await router.push("/administrator/login");
    }     

    return {
        closeSession,
        malwareAnalysis,
        profile,
        changePassword,
        externalUserPortal,
        entityUserPortal,
        administratorPortal,
    };
}
