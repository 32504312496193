
import { defineComponent, onErrorCaptured, ref, Ref } from "vue";
import ErrorModal from "@/modals/ErrorModal.vue";

import { useRouter } from "vue-router";
import { userStore } from "@/store/userStore";
import { sessionStore } from "@/store/sessionStore";
import DeviceNotCompatible from "@/components/DeviceNotCompatible.vue";

export default defineComponent({
  name: "UploadSample",
  components: {
    ErrorModal,
    DeviceNotCompatible,
  },
  setup() {
    const hasError: Ref<boolean> = ref(false);
    const message: Ref<string> = ref("");
    const domainRoute: Ref<string> = ref("");
    const router = useRouter();

    onErrorCaptured((error: any) => {
      domainRoute.value = router.currentRoute.value.meta.domainRoute as string;

      message.value = error.message;
      hasError.value = true;

      if (error.code == 401) {
        router.push(domainRoute.value.concat("login"));
        userStore().closeSession();
        sessionStore().logout();
      }
    });

    return {
      hasError,
      message,
    };
  },
});
