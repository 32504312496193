
import { computed, defineComponent, ref, Ref } from "vue";
import { codeMFAValidator } from "@/services/authentication/authenticationValidators/codeMFAValidator";
import {confirmLoginActions} from "@/uses/authentication/managementConfirmLoginActions";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "CodeMFA",
  components: {
    Header,
  },
  setup() {
    const { code, successForm } = codeMFAValidator();
    const { confirmLogin, returnLogin } = confirmLoginActions();

    return {
      code,
      successForm,
      confirmLogin,
      returnLogin,
    };
  },
});
