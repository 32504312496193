
import { computed, defineComponent, ref, Ref, watch } from "vue";
import CreateEditUser from "@/modals/CreateEditUser.vue";
import { baseUserActions } from "@/uses/administrator/managementBaseUserActions";
import Paginator from "@/components/Paginator.vue";
import { paginator } from "@/uses/administrator/paginator";
import ConfirmationModal from "@/modals/ConfirmationModal.vue";
import SuccessfulModal from "@/modals/SuccessfulModal.vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "Users",
  components: {
    CreateEditUser,
    SuccessfulModal,
    Paginator,
    ConfirmationModal,
    Header,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      users,
      setEntityId,
      modalCreateEditUser,
      closeCreateEditUser,
      showCreateEditUser,
      messageSuccess,
      successfulModal,
      showSuccessfulModal,
      closeSuccessfulModal,
      entityOption,
      selectedItemEdit,
      deleteUserProcess,
      closeConfirmation,
      modalConfirmation,
      selectedUser,
      setEntityName,
      actionLabel,
    } = baseUserActions();
    setEntityId(props.id);
    setEntityName(props.name)
    const {
      isFirstPage,
      isLastPage,
      nextPage,
      previousPage,
      returnData,
      maxItems,
    } = paginator();

    maxItems.value = 7;

    const paginateUsers = computed(() => {
      return returnData(users.value);
    });

    watch(
      () => props.id,
      () => {
        setEntityId(props.id);
      }
    );

    return {
      modalCreateEditUser,
      showCreateEditUser,
      closeCreateEditUser,
      entityOption,
      users,
      isFirstPage,
      isLastPage,
      nextPage,
      previousPage,
      modalConfirmation,
      closeConfirmation,
      successfulModal,
      showSuccessfulModal,
      closeSuccessfulModal,
      paginateUsers,
      messageSuccess,
      selectedItemEdit,
      deleteUserProcess,
      selectedUser,
      actionLabel,
    };
  },
});
