import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { EmailRule } from "@/services/commons/validator/rules/EmailRule";
import { EqualsRule } from "@/services/commons/validator/rules/EqualsRule";
import { validator } from "@/services/commons/validator/validator";
import { ColombiaNumberPhoneRule } from "@/services/commons/validator/rules/ColombiaNumberPhoneRule";

export function uploadSampleProfilePublicValidator() {
  const organizationType: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [new RequiredRule("El tipo de organización es obligatorio")],
  });

  const code: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [new RequiredRule("El código SIGEP es obligatorio")],
  });

  const entityName: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [new RequiredRule("El nombre de la entidad es obligatorio")],
  });

  const name: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [new RequiredRule("El nombre es obligatorio")],
  });

  const lastname: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [new RequiredRule("El apellido es obligatorio")],
  });

  const phoneNumber: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El número de teléfono es obligatorio"),
      new ColombiaNumberPhoneRule(
        "El número de teléfono debe ser un número válido"
      ),
    ],
  });

  const email: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El correo es obligatorio"),
      new EmailRule("Correo inválido"),
    ],
  });

  const confirmEmail: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("Confirma tu correo"),
      new EmailRule("Correo inválido"),
      new EqualsRule("Los correos no coinciden"),
    ],
  });

  watch(
    () => organizationType.value.val,
    () => {
      organizationType.value.enabled = true;
    }
  );

  watch(
    () => entityName.value.val,
    () => {
      entityName.value.enabled = true;
    }
  );

  watch(
    () => name.value.val,
    () => {
      name.value.enabled = true;
    }
  );

  watch(
    () => lastname.value.val,
    () => {
      lastname.value.enabled = true;
    }
  );

  watch(
    () => phoneNumber.value.val,
    () => {
      phoneNumber.value.enabled = true;
    }
  );

  watch(
    () => email.value.val,
    () => {
      email.value.enabled = true;
    }
  );

  watch(
    () => confirmEmail.value.val,
    () => {
      confirmEmail.value.enabled = true;
    }
  );

  function validate(
    field: GenericField,
    otherValue?: string | null
  ): GenericField {
    return validator().validateField(field, otherValue);
  }

  const successForm = computed(() => {
    organizationType.value = validate(organizationType.value);
    entityName.value = validate(entityName.value);
    name.value = validate(name.value);
    lastname.value = validate(lastname.value);
    phoneNumber.value = validate(phoneNumber.value);
    email.value = validate(email.value);
    confirmEmail.value = validate(confirmEmail.value, email.value.val);
    return !(
      organizationType.value.isSuccess &&
      entityName.value.isSuccess &&
      name.value.isSuccess &&
      lastname.value.isSuccess &&
      phoneNumber.value.isSuccess &&
      email.value.isSuccess &&
      confirmEmail.value.isSuccess
    );
  });

  return {
    organizationType,
    code,
    entityName,
    name,
    lastname,
    phoneNumber,
    email,
    confirmEmail,
    successForm,
  };
}
