
import { computed, defineComponent, ref, Ref } from "vue";

export default defineComponent({
  name: "ErrorModal",
  props: {
    message: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props, context) {
    function close() {
      return context.emit("update:close", false);
    }

    return {
      close,
    };
  },
});
