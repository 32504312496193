import {
    create_cognito,
    confirm_email,
    resent_confirmation_code, verify_mfa,
} from "@/services/authentication/api/authenticationUrls";
import { RestResponse } from "@/services/commons/rest/RestResponse";
import { sendRequest } from "@/services/commons/rest/restClient";

export interface CreateCognitoRequest {
    email: string;
    password: string;
}

export interface ConfirmEmailRequest {
    email: string;
    code: string;
}

export interface ResendConfirmationCodeRequest {
    email: string;
}

export interface VerifyMFARequest {
    email: string;
    session: string;
    code: string;
}

export interface VerifyMFAResponse {
    session: string;
    error: boolean;
}

export function managementAuthenticationService() {
    async function createCognitoUser(request: CreateCognitoRequest): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(create_cognito);
        const response: RestResponse = await sendRequest(url, "POST", JSON.stringify(request), null);

        if (!response.isSuccess) {
            if (response.statusCode == 409) {
                throw {
                    message: "El usuario ya existe.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
    }

    async function confirmEmail(request: ConfirmEmailRequest): Promise<boolean> {
        const url = process.env.VUE_APP_BASE_URL.concat(confirm_email);
        const response: RestResponse = await sendRequest(url, "POST", JSON.stringify(request), null);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                return true;
            }
            else if (response.statusCode == 404) {
                throw {
                    message: "El usuario no existe.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
        return false;
    }

    async function resendConfirmationCode(request: ResendConfirmationCodeRequest): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(resent_confirmation_code);
        const response: RestResponse = await sendRequest(url, "POST", JSON.stringify(request), null);

        if (!response.isSuccess) {
            if (response.statusCode == 404) {
                throw {
                    message: "El código ingresado es invalido.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
    }

    async function verifyMFA(request: VerifyMFARequest): Promise<VerifyMFAResponse> {
        const url = process.env.VUE_APP_BASE_URL.concat(verify_mfa);
        const response: RestResponse = await sendRequest(url, "POST", JSON.stringify(request), null);


        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                return {
                    session: "",
                    error: true,
                };
            }
            else {
                const message = JSON.parse(JSON.stringify(response.content))
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }

        return JSON.parse(JSON.stringify(response.content));
    }

    return {
        createCognitoUser,
        confirmEmail,
        resendConfirmationCode,
        verifyMFA,
    }
}

