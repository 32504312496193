import {computed, ref, Ref} from "vue";
import { useRouter } from "vue-router";
import { userStore } from "@/store/userStore";

export function headerOptionsActions() {

    const router = useRouter();
    const { isAuthenticated } = userStore();

    async function externalUserPortal() {
        await router.push("/external-user/upload-sample");
    }    

    async function entityUserPortal() {
        await router.push("/user/login");
    }      

    async function administratorPortal() {
        await router.push("/administrator/login");
    }

    const isNotVisibleOptions = computed(() => !isAuthenticated())

    return {
        externalUserPortal,
        entityUserPortal,
        administratorPortal,
        isNotVisibleOptions
    };
}
