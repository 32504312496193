import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { NumericRule } from "@/services/commons/validator/rules/NumericRule";
import { validator } from "@/services/commons/validator/validator";

export function confirmCodeValidator() {
  const code: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El código es obligatorio"),
      new NumericRule("El código debe ser numérico"),
    ],
  });

  watch(
    () => code.value.val,
    () => {
      code.value.enabled = true;
    }
  );

  function validate(field: GenericField): GenericField {
    return validator().validateField(field);
  }

  const successForm = computed(() => {
    code.value = validate(code.value);
    return !(code.value.isSuccess);
  });

  return {
    code,
    successForm,
  };
}
