import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { validator } from "@/services/commons/validator/validator";

export function entityValidator() {
  const entityName: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El nombre de la entidad es obligatorio"),
    ],
  });

  const organizationType: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El tipo de organización es obligatorio"),
    ],
  });

  const sector: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El sector es obligatorio"),
    ],
  });

  const code: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El código SIGEP es obligatorio"),
    ],
  });

  const fileName: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
    ],
  });

  watch(
    () => entityName.value.val,
    () => {
      entityName.value.enabled = true;
    }
  );

  watch(
    () => organizationType.value.val,
    () => {
      organizationType.value.enabled = true;
    }
  );

  watch(
    () => sector.value.val,
    () => {
      sector.value.enabled = true;
    }
  );

  watch(
    () => fileName.value.val,
    () => {
      fileName.value.enabled = true;
    }
  );

  function validate(field: GenericField): GenericField {
    return validator().validateField(field);
  }

  const successForm = computed(() => {
    entityName.value = validate(entityName.value);
    organizationType.value = validate(organizationType.value);
    sector.value = validate(sector.value);
    return !(entityName.value.isSuccess && organizationType.value.isSuccess && sector.value.isSuccess && fileName.value.isSuccess);
  });

  return {
    entityName,
    organizationType,
    sector,
    code,
    fileName,
    successForm,
  };
}
