import Layout from "@/views/general/Layout.vue";
import LoginFull from "@/views/user/LoginFull.vue";
import LoginFullAdmin from "@/views/administrator/LoginFull.vue";
import RegisterPassword from "@/views/user/RegisterPassword.vue";
import AuthenticationFactor from "@/views/user/AuthenticationFactor.vue";
import CodeMFA from "@/views/user/CodeMFA.vue";
import ConfirmCode from "@/views/user/ConfirmCode.vue";
import RecoverPassword from "@/views/user/RecoverPassword.vue";
import UploadSample from "@/views/user/UploadSample.vue";
import Profile from "@/views/user/Profile.vue";
import ChangePassword from "@/views/user/ChangePassword.vue";
import { isAuthenticate } from "@/router/isAuthenticate";

export const authRoutes = [
  {
    path: "/user/",
    component: Layout,
    children: [
      {
        path: "login",
        component: LoginFull, 
        meta: {
          domainRoute: "/user/",
        },
      },
      {
        path: "register-password",
        component: RegisterPassword, 
        meta: {
          domainRoute: "/user/",
        },
      },
      {
        path: "authentication-factor",
        component: AuthenticationFactor, 
        meta: {
          domainRoute: "/user/",
        },
      },
      {
        path: "code-mfa",
        component: CodeMFA, 
        meta: {
          domainRoute: "/user/",
        },
      },
      {
        path: "confirm-code",
        component: ConfirmCode,
        meta: {
          domainRoute: "/user/",
        },
      },
      {
        path: "recover-password",
        component: RecoverPassword, 
        meta: {
          domainRoute: "/user/",
        },
      },
    ],
  },
  {
    path: "/administrator/",
    component: Layout,
    children: [
      {
        path: "login",
        component: LoginFullAdmin,
        meta: {
          domainRoute: "/administrator/",
        },
      },
      {
        path: "register-password",
        component: RegisterPassword, 
        meta: {
          domainRoute: "/administrator/",
        },
      },
      {
        path: "authentication-factor",
        component: AuthenticationFactor, 
        meta: {
          domainRoute: "/administrator/",
        },
      },
      {
        path: "code-mfa",
        component: CodeMFA, 
        meta: {
          domainRoute: "/administrator/",
        },
      },
      {
        path: "confirm-code",
        component: ConfirmCode,
        meta: {
          domainRoute: "/administrator/",
        },
      },
      {
        path: "recover-password",
        component: RecoverPassword, 
        meta: {
          domainRoute: "/administrator/",
        },
      },
    ],
  },
];
