
import { computed, defineComponent, ref, Ref } from "vue";
import { headerActions } from "@/uses/administrator/headerActions";

export default defineComponent({
  name: "Menu",

  setup() {
    const { mainMenu } = headerActions();

    return {
      mainMenu,
    };
  },
});
