import {computed, onMounted, ref, Ref} from "vue";
import { useRouter } from "vue-router";
import { userStore } from "@/store/userStore";
import {managementEntityService} from "@/services/administrator/api/managementEntityService";
import {sessionStore} from "@/store/sessionStore";

export function headerActions() {

    const router = useRouter();
    const { isAuthenticated, closeSession } = userStore();
    const domainRoute: Ref<string> = ref("");

    onMounted(async () => {
        domainRoute.value = (await router.currentRoute.value.meta.domainRoute) as string;
    })

    async function closeSessionAdmin() {
        await closeSession();
        await sessionStore().logout();
        await router.push("/external-user/upload-sample");
    }

    async function mainMenu() {
        await router.push("/administrator/main-menu");
    }

    const isAdmin = computed(() => {
        const currentRoute = router.currentRoute.value.path;
        return domainRoute.value == "/administrator/" && isAuthenticated();
    })

    const isUser = computed( () => {
        return domainRoute.value == "/user/" && isAuthenticated();
    })

    const showMenuMainMenu = computed(() => {
        const currentRoute = router.currentRoute.value.path;
        return currentRoute.includes("main-menu") ? false : isAdmin.value;
    });


    return {
        closeSessionAdmin,
        mainMenu,
        isAdmin,
        isUser,
        showMenuMainMenu,
    };
}
