
import {computed, defineComponent, onBeforeMount, onBeforeUnmount, ref, Ref} from "vue";
import { emailValidator } from "@/services/authentication/authenticationValidators/emailValidator";
import { passwordValidator } from "@/services/authentication/authenticationValidators/passwordValidator";
import { loginActions } from "@/uses/authentication/managementLoginActions";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "LoginFull",
  components: {
    Header,
  },
  setup() {

    const { email, successEmailForm } = emailValidator();
    const { password, successPasswordForm } = passwordValidator();
    const {
      showPassword,
      buttonText,
      preLogin,
      login,
      returnEmail,
      forgotPassword,
    } = loginActions();

    async function loginTransaction(): Promise<void> {
      await login(email.value.val ?? "", password.value.val ?? "", 2)
    }

    async function returnLoginTransaction() {
      password.value.val = "";
      await new Promise(r => setTimeout(r, 100));
      password.value.enabled = false;
      returnEmail();
    }

    const chooseValidation = computed(() => {
      return showPassword.value
        ? successEmailForm.value || successPasswordForm.value
        : successEmailForm.value;
    });

    return {
      chooseValidation,
      preLogin,
      loginTransaction,
      returnEmail,
      forgotPassword,
      buttonText,
      showPassword,
      email,
      password,
      successEmailForm,
      successPasswordForm,
      returnLoginTransaction,
    };
  },
});
