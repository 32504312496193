import { readonly, Ref, ref } from "vue";
import { UserResponse } from "@/services/administrator/api/managementAdministratorService";
import { encryptStorage } from "@/services/commons/security/Encrypt";

export interface User {
  accessToken: string;
  lastName: string | null;
  firstName: string | null;
  id: string;
  profile: string | null;
  entityId: string;
  mobilePhone: string | null;
  localPhone: string | null;
  userCognitoId: string | null;
  srcImageEntity: string | null;
}

const emptyUser: User = {
  id: "",
  firstName: "",
  lastName: "",
  profile: "",
  entityId: "",
  mobilePhone: null,
  localPhone: null,
  accessToken: "",
  userCognitoId: "",
  srcImageEntity: null,
};

const user: Ref<User> = ref(emptyUser);

export function userStore() {

  function loadUserMemory(): void {
    const loadUser = encryptStorage.getItem("user");
    if (loadUser && loadUser != null && loadUser != undefined && loadUser != "") {
      user.value = loadUser;
    }
  }

  async function persistUser(): Promise<void> {
    encryptStorage.setItem("user", JSON.stringify(user.value));
  }

  async function closeSession() {
    user.value.accessToken = "";
    encryptStorage.removeItem("user");
  }

  function isAuthenticated(): boolean {
    return (user.value.accessToken.length > 0);
  }

  async function setUser(newUser: UserResponse) {
    user.value.id = newUser.id;
    user.value.firstName = newUser.firstName;
    user.value.lastName = newUser.lastName;
    user.value.profile = newUser.profile;
    user.value.entityId = newUser.entityId;
    user.value.mobilePhone = newUser.mobilePhone;
    user.value.localPhone = newUser.localPhone;
    user.value.userCognitoId = newUser.userCognitoId;
    user.value.srcImageEntity = process.env.VUE_APP_BASE_URL.concat(
      "/Entity/GetIcon/"
    ).concat(user.value.entityId);
    await persistUser();
  }

  async function setUserUpdated(
    name: string,
    lastname: string,
    mobile: string,
    phone: string
  ) {
    user.value.firstName = name;
    user.value.lastName = lastname;
    user.value.mobilePhone = mobile;
    user.value.localPhone = phone;
    await persistUser();
  }

  async function setToken(token: string) {
    user.value.accessToken = token;
    await persistUser();
  }

  function getToken(): string {
    return user.value.accessToken;
  }

  function getUserId(): string {
    return user.value.id;
  }

  function getEntityId(): string {
    return user.value.entityId;
  }

  function getEntityImage(): string {
    return user.value.srcImageEntity || "";
  }

  return {
    getEntityId,
    setToken,
    setUser,
    closeSession,
    user: readonly(user),
    getToken,
    getUserId,
    getEntityImage,
    isAuthenticated,
    setUserUpdated,
    loadUserMemory,
  };
}
