import { RestResponse } from "@/services/commons/rest/RestResponse";

export async function sendRequest(
    url: string,
    verb: string,
    body?: any,
    customHeaders?: any,
    removeContentType?: boolean | false
): Promise<RestResponse> {

    const jsonHeader = {
        "Content-Type": "application/json",
    }

    const headers = customHeaders != null ? { ...jsonHeader, ...customHeaders } : jsonHeader

    if (removeContentType) {
        delete headers["Content-Type"];
    }

    const config = {
        method: verb,
        headers: headers,
        body: body,
    };
    const apiResponse: RestResponse = {
        isSuccess: false,
        statusCode: 500,
        content: "",
    };

    try {
        const response = await fetch(url, config);

        if (response.status >= 500) {
            throw new Error(response.toString());
        }

        apiResponse.isSuccess = response.ok;
        apiResponse.statusCode = response.status;

        try {
            apiResponse.content = await response.json();
        } catch (e) {
            apiResponse.content = "";
        }
    }
    catch (e) {
    }

    return apiResponse;
}