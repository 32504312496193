import { userStore } from "@/store/userStore";
import {
    CreateEntitiesRequest,
    managementEntityService
} from "@/services/administrator/api/managementEntityService";
import { useRouter } from "vue-router";

export function createEntitiesByFileActions() {

    const router = useRouter();
    async function createEntitiesByFile(
        file: any,
    ): Promise<void> {
        const createEntitiesRequest: CreateEntitiesRequest = {
            file: file,
        };
        await managementEntityService().createEntitiesByFile(createEntitiesRequest, userStore().getToken());
        await router.push("/administrator/entity");
    }

    return {
        createEntitiesByFile,
    }
}