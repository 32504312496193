import { inject, onMounted, ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { userStore } from "@/store/userStore";
import {
  CreateEntityAnalysisRequest,
  managementEntityAnalysisService,
} from "@/services/entity/api/managementUploadSampleService";
import { generateUUID } from "@/services/commons/uuid/uuid";

export function uploadSampleActions() {
  const urlOption: Ref<boolean> = ref(true);
  const fileOption: Ref<boolean> = ref(false);
  const showIconFile: Ref<boolean> = ref(false);
  const disabledUrlBox: Ref<boolean> = ref(false);
  const termsAndConditions: Ref<boolean> = ref(false);
  const modalTermsAndConditions: Ref<boolean> = ref(false);
  const validateCaptcha: Ref<boolean> = ref(false);
  const captchaKey: Ref<string> = inject("captchaKey", ref(""));
  const router = useRouter();

  function selectSampleOption() {
      urlOption.value = !urlOption.value;
      fileOption.value = !fileOption.value;

    if(urlOption.value) {
      fileOption.value = false;
      showIconFile.value = false;
      disabledUrlBox.value = false;
    } else {
      fileOption.value = true;
      showIconFile.value = true;
      disabledUrlBox.value = true;
    }
  }

  async function resetForm() {
    await router.push("/")
    await router.push("/user/upload-sample")
  }

  function showTermsAndConditions() {
    modalTermsAndConditions.value = true;
  }

  function closeTermsAndConditions() {
    modalTermsAndConditions.value = false;
  }

  async function uploadSample(
    incidentNumber: string,
    sampleContext: string,
    url: string | null,
    file: any | null
  ): Promise<void> {
    const type: Ref<number> = ref(1);
    type.value = url != null ? 2 : 1;
    const createEntityAnalysisRequest: CreateEntityAnalysisRequest = {
        id: generateUUID(),
        type: type.value,
        incidentNumber: incidentNumber,
        sampleContext: sampleContext,
        url: url,
        file: file,
        entityId: userStore().getEntityId(),
        userId: userStore().getUserId()
    };
    await managementEntityAnalysisService().createEntityAnalysis(createEntityAnalysisRequest, userStore().getToken());
  }

  function verifyCaptcha(response: string): void {
    validateCaptcha.value = (response != "" && response.length > 0) ? true : false;
  }

  function expiredCaptcha(): void {
    validateCaptcha.value = false
  }

  function getEntityImage(): string {
    return userStore().getEntityImage();
  }

  return {
    urlOption,
    fileOption,
    selectSampleOption,
    showIconFile,
    disabledUrlBox,
    uploadSample,
    termsAndConditions,
    resetForm,
    validateCaptcha,
    verifyCaptcha,
    captchaKey,
    expiredCaptcha,
    modalTermsAndConditions,
    showTermsAndConditions,
    closeTermsAndConditions,
    getEntityImage,
    user: userStore().user
  };
}
