
import {computed, defineComponent, Ref, ref, watch} from "vue";
import { entityValidator } from "@/services/administrator/administratorValidators/entityValidator";
import { managementEntityActions } from "@/uses/administrator/managementEntityActions";
import { EntityResponse } from "@/services/administrator/api/managementEntityService";
import DetailsEntitySigepModal from "@/modals/DetailsEntitySigepModal.vue";
import Spinner from "@/components/Spinner.vue";

export default defineComponent({
  name: "CreateEditEntity",
  components: {
    DetailsEntitySigepModal,
    Spinner,
  },
  props: {
    actionLabel: {
      type: String,
      default: "CREAR",
    },
    entity: {
      type: Object as () => EntityResponse,
      required: false,
    },
  },
  setup(props, context) {
    const showSpinner : Ref<boolean> = ref(false)

    async function closeModal() {
      await goToEntities();
      context.emit("close", false);
    }

    const {
      entityName,
      organizationType,
      sector,
      code,
      fileName,
      successForm,
    } = entityValidator();
    const {
      createEntity,
      updateEntity,
      goToEntities,
      detectSigep,
      entitySigepInfo,
      showEntitySigepInfo,
      showDetail,
    } = managementEntityActions();
    fullForm();

    watch(
      () => props.entity,
      () => {
        fullForm();
      }
    );

    function fullForm() {
      entityName.value.val = props.entity?.name || "";
      organizationType.value.val = props.entity?.type || "";
      sector.value.val = props.entity?.sector || "";
      code.value.val = props.entity?.sigepCode || "";
    }

    const fileSample = ref(null);
    const file = ref(null);

    function loadFile(event: any) {
      const fileUploaded = event.target.files[0];
      fileName.value.val = fileUploaded.name;
      file.value = fileUploaded;
    }

    async function createEditEntityTransaction() {
      try {
        if (props.entity?.id) {
          await updateEntity(
            props.entity.id,
            entityName.value.val || "",
            organizationType.value.val || "",
            sector.value.val || "",
            file.value
          );
        } else {
          await createEntity(
            entityName.value.val || "",
            organizationType.value.val || "",
            sector.value.val || "",
            code.value.val,
            file.value
          );
        }

        resetValidator();
        context.emit("close", true);
      } catch (e) {
        resetValidator();
        context.emit("close", false);
        throw e;
      }
    }

    const sigepEnabled = computed(() => {
      return props.entity?.id;
    });

    function resetValidator() {
      entityName.value.val = "";
      organizationType.value.val = "";
      sector.value.val = "";
      code.value.val = "";
      fileName.value.val = "";
      entityName.value.enabled = false;
      organizationType.value.enabled = false;
      sector.value.enabled = false;
      code.value.enabled = false;
      fileName.value.enabled = false;
    }

    async function detectSigepChange(event: any) {
      const target = event.target.value;
      if (target.length > 0) {
        showSpinner.value = true;
        try {
          await detectSigep(event.target.value);
          entityName.value.val = entitySigepInfo.value?.nombre || null;
          await new Promise((r) => setTimeout(r, 700));
        } catch (e) {
          throw e;
        } finally {
          showSpinner.value = false;
        }
      }
    }

    return {
      fileSample,
      entityName,
      organizationType,
      sector,
      code,
      fileName,
      successForm,
      createEditEntityTransaction,
      closeModal,
      loadFile,
      sigepEnabled,
      detectSigepChange,
      entitySigepInfo,
      showEntitySigepInfo,
      showDetail,
      showSpinner,
    };
  },
});
