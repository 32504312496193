import { onMounted, ref, Ref } from "vue";
import { userStore } from "@/store/userStore";
import { managementUserService, UserResponse } from "@/services/administrator/api/managementUserService";
import { useRouter } from "vue-router";
import {EntityResponse, managementEntityService} from "@/services/administrator/api/managementEntityService";

export function baseUserActions() {

    const router = useRouter();
    const users: Ref<UserResponse[]> = ref([]);
    const entityId: Ref<string> = ref("");
    const entityName: Ref<string> = ref("")
    const messageSuccess: Ref<String> = ref("");
    const actionLabel: Ref<string> = ref("CREAR");
    const modalCreateEditUser: Ref<boolean> = ref(false);
    const selectedUser: Ref<UserResponse | null> = ref(null);
    const successfulModal: Ref<boolean> = ref(false);
    const modalConfirmation: Ref<boolean> = ref(false);

    function closeCreateEditUser(wasSuccessful: boolean) {
        modalCreateEditUser.value = false;
        if (wasSuccessful) {
            showSuccessfulModal();
            messageSuccess.value = `Operación exitosa al ${actionLabel.value.toLowerCase()} el usuario`
        }
    }

    function showCreateEditUser(isEdit: boolean = false) {
        modalCreateEditUser.value = true;
        if (!isEdit) {
            selectedUser.value = null;
        }
        actionLabel.value = isEdit ? "EDITAR" : "CREAR";
    }

    async function setEntityId(id: string) {
        entityId.value = id;
        users.value = await managementUserService().findUsersByEntity(userStore().getToken(), entityId.value);
    }

    async function setEntityName(name: string) {
        entityName.value = name;
    }

    async function deleteUser(
        id: string,
    ): Promise<void> {
        selectedUser.value = null;
        await managementUserService().deleteUser(id, userStore().getToken());
        messageSuccess.value = `El usuario ha sido eliminado correctamente`
        showSuccessfulModal();
    }

    function showSuccessfulModal() {
        successfulModal.value = true;
    }

    async function closeSuccessfulModal() {
        successfulModal.value = false;
        await router.push('/')
        await router.push(`/administrator/users/${entityId.value}/${entityName.value}`);
    }

    async function entityOption() {
        await router.push("/administrator/entity");
    }

    function selectedItemEdit(oldUser: UserResponse) {
        selectedUser.value = oldUser;
        showCreateEditUser(true);
    }

    function deleteUserProcess(user: UserResponse) {
        selectedUser.value = user;
        modalConfirmation.value = true;
    }

    async function closeConfirmation(accept: boolean) {
        modalConfirmation.value = false;
        if (accept) {
            await deleteUser(selectedUser.value?.id || "");
        }
    }

    return {
        deleteUser,
        users,
        entityId,
        setEntityId,
        modalCreateEditUser,
        closeCreateEditUser,
        showCreateEditUser,
        messageSuccess,
        successfulModal,
        showSuccessfulModal,
        closeSuccessfulModal,
        entityOption,
        selectedItemEdit,
        deleteUserProcess,
        closeConfirmation,
        modalConfirmation,
        selectedUser,
        setEntityName,
        actionLabel,
    }
}