
import { computed, defineComponent, ref, Ref, watch } from "vue";
import { uploadSampleActions } from "@/uses/user/uploadSampleActions";
import { uploadSampleProfilePrivateValidator } from "@/services/user/externalUserValidators/uploadSampleProfilePrivateValidator";
import { uploadUrlValidator } from "@/services/user/externalUserValidators/uploadUrlValidator";
import { uploadFileValidator } from "@/services/user/externalUserValidators/uploadFileValidator";
import TermsAndConditions from "@/modals/TermsAndConditions.vue";
import { VueRecaptcha } from "vue-recaptcha";
import Spinner from "@/components/Spinner.vue";

export default defineComponent({
  name: "FormUploadSampleProfileCioCiso",
  components: {
    TermsAndConditions,
    VueRecaptcha,
    Spinner,
  },
  props: {
    profile: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props, context) {
    const {
      urlOption,
      fileOption,
      selectSampleOption,
      showIconFile,
      termsAndConditions,
      showTermsAndConditions,
      modalTermsAndConditions,
      closeTermsAndConditions,
      disabledUrlBox,
      uploadSample,
      validateCaptcha,
      verifyCaptcha,
      captchaKey,
      expiredCaptcha,
      showDetail,
      allowsNews,
    } = uploadSampleActions();
    const {
      sector,
      entityName,
      name,
      lastname,
      phoneNumber,
      email,
      confirmEmail,
      successForm,
    } = uploadSampleProfilePrivateValidator();
    const { successURL, url } = uploadUrlValidator();
    const { successFile, fileName } = uploadFileValidator();
    const fileSample = ref(null);
    const file = ref(null);
    const showSpinner: Ref<boolean> = ref(false);

    const fullValidator = computed(() => {
      const initialValidation = successForm.value || !termsAndConditions.value
      || !validateCaptcha.value;
      return urlOption.value
        ? initialValidation || successURL.value
        : initialValidation || successFile.value;
    });

    function loadFile(event: any) {
      const fileUploaded = event.target.files[0];
      fileName.value.val = fileUploaded.name;
      file.value = fileUploaded;
      url.value.val = null;
    }

    async function uploadSampleTransaction() {
      showSpinner.value = true;
      await uploadSample(
        props.profile,
        null,
        sector.value.val,
        entityName.value.val || "",
        null,
        name.value.val || "",
        lastname.value.val || "",
        Number(phoneNumber.value.val),
        email.value.val || "",
        url.value.val,
        file.value
      );
      await new Promise((r) => setTimeout(r, 700));
      showSpinner.value = false;
      context.emit("success");
    }

    watch(
      () => urlOption.value,
      () => {
        url.value.val = urlOption.value ? url.value.val : "";
      }
    );

    watch(
      () => fileOption.value,
      () => {
        fileName.value.val = fileOption.value ? fileName.value.val : "";
        file.value = null;
      }
    );

    return {
      fileSample,
      loadFile,
      urlOption,
      fileOption,
      selectSampleOption,
      successFile,
      showIconFile,
      disabledUrlBox,
      sector,
      entityName,
      name,
      lastname,
      phoneNumber,
      email,
      url,
      fileName,
      confirmEmail,
      successForm,
      successURL,
      uploadSampleTransaction,
      fullValidator,
      termsAndConditions,
      showTermsAndConditions,
      modalTermsAndConditions,
      closeTermsAndConditions,
      verifyCaptcha,
      captchaKey,
      expiredCaptcha,
      showSpinner,
      showDetail,
      allowsNews,
    };
  },
});
