import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/Back.png'
import _imports_1 from '@/assets/icons/Next.png'


const _withScopeId = n => (_pushScopeId("data-v-6b1bd45f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-paginator" }
const _hoisted_2 = { class: "paginator" }
const _hoisted_3 = ["is-disabled"]
const _hoisted_4 = ["is-disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "wrapper-button margin",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.previousPage && _ctx.previousPage(...args)))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "back",
          class: "icon-paginator",
          "is-disabled": _ctx.isFirstPage
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", {
        class: "wrapper-button",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
      }, [
        _createElementVNode("img", {
          src: _imports_1,
          alt: "next",
          class: "icon-paginator",
          "is-disabled": _ctx.isLastPage
        }, null, 8, _hoisted_4)
      ])
    ])
  ]))
}