import { onMounted, ref, Ref } from "vue";
import { userStore } from "@/store/userStore";
import { EntityResponse, managementEntityService } from "@/services/administrator/api/managementEntityService";
import { useRouter } from "vue-router";

export function baseEntityActions() {

    const messageSuccess: Ref<String> = ref("");
    const actionLabel: Ref<string> = ref("CREAR");
    const modalCreateEditEntity: Ref<boolean> = ref(false);
    const successfulModal: Ref<boolean> = ref(false);
    const entities: Ref<EntityResponse[]> = ref([]);
    const selectedEntity: Ref<EntityResponse | null> = ref(null);
    const modalConfirmation: Ref<boolean> = ref(false);
    const modalCreateEntityUploadFile: Ref<boolean> = ref(false);
    const router = useRouter();


    onMounted(async () => {
        entities.value = await managementEntityService().allEntities(userStore().getToken());
    });

    function closeCreateEditEntity(wasSuccessful: boolean) {
        modalCreateEditEntity.value = false;
        if (wasSuccessful) {
            showSuccessfulModal();
            messageSuccess.value = `Operación exitosa al ${actionLabel.value.toLowerCase()} la entidad`
        }
    }

    function closeUploadEntitiesFile(wasSuccessful: boolean) {
        modalCreateEntityUploadFile.value = false
        if (wasSuccessful) {
            showSuccessfulModal();
            messageSuccess.value = `Las entidades se han creado correctamente`
        }
    }

    function showCreateEditEntity(isEdit: boolean = false) {
        modalCreateEditEntity.value = true;
        if (!isEdit) {
            selectedEntity.value = null
        }
        actionLabel.value = isEdit ? "EDITAR" : "CREAR"
    }

    function showSuccessfulModal() {
        successfulModal.value = true;
    }

    async function closeSuccessfulModal() {

        successfulModal.value = false;
        await router.push("/")
        await router.push("/administrator/entity")
    }

    function selectedItemEdit(oldEntity: EntityResponse) {
        selectedEntity.value = oldEntity;
        showCreateEditEntity(true);
    }

    function deleteEntityProcess(entity: EntityResponse) {
        selectedEntity.value = entity;
        modalConfirmation.value = true;
    }

    async function closeConfirmation(accept: boolean) {
        modalConfirmation.value = false;
        if (accept) {
            await deleteEntity(selectedEntity.value?.id || "");
        }
    }

    async function deleteEntity(
        id: string,
    ): Promise<void> {
        selectedEntity.value = null;
        await managementEntityService().deleteEntity(userStore().getToken(), id);
        messageSuccess.value = `La entidad ha sido eliminada correctamente`
        showSuccessfulModal();
    }

    function showUploadFileEntities() {
        modalCreateEntityUploadFile.value = true
    }

    async function manageUsers(id: string, name: string) {
        await router.push(`/administrator/users/${id}/${name}`);
    }

    function logoPath(entityId: string) : string {
        return process.env.VUE_APP_BASE_URL.concat("/Entity/GetIcon/").concat(entityId)
    }

    return {
        entities,
        modalCreateEditEntity,
        showCreateEditEntity,
        closeCreateEditEntity,
        actionLabel,
        successfulModal,
        showSuccessfulModal,
        closeSuccessfulModal,
        messageSuccess,
        selectedEntity,
        selectedItemEdit,
        deleteEntityProcess,
        modalConfirmation,
        closeConfirmation,
        showUploadFileEntities,
        modalCreateEntityUploadFile,
        closeUploadEntitiesFile,
        manageUsers,
        logoPath,
    }
}