import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { EmailRule } from "@/services/commons/validator/rules/EmailRule";
import { validator } from "@/services/commons/validator/validator";

export function emailValidator() {
  const email: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El correo es obligatorio"),
      new EmailRule("Correo inválido"),
    ],
  });

  watch(
    () => email.value.val,
    () => {
      email.value.enabled = true;
    }
  );

  function validate(field: GenericField): GenericField {
    return validator().validateField(field);
  }

  const successForm = computed(() => {
    email.value = validate(email.value);
    return !(email.value.isSuccess);
  });

  return {
    email,
    successEmailForm: successForm,
  };
}
