import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { NumericRule } from "@/services/commons/validator/rules/NumericRule";
import { validator } from "@/services/commons/validator/validator";

export function profileValidator() {
  const name: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El nombre del usuario es obligatorio"),
    ],
  });

  const lastname: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El apellido del usuario es obligatorio"),
    ],
  });

  const cellPhone: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El número de celular es obligatorio"),
      new NumericRule("El número de celular debe ser numérico"),
    ],
  });

  const phone: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El número de teléfono es obligatorio"),
      new NumericRule("El teléfono fijo debe ser numérico"),
    ],
  });

  const email: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
    ],
  });

  watch(
    () => name.value.val,
    () => {
      name.value.enabled = true;
    }
  );

  watch(
    () => lastname.value.val,
    () => {
      lastname.value.enabled = true;
    }
  );

  watch(
    () => cellPhone.value.val,
    () => {
      cellPhone.value.enabled = true;
    }
  );

  watch(
    () => phone.value.val,
    () => {
      phone.value.enabled = true;
    }
  );

  function validate(field: GenericField): GenericField {
    return validator().validateField(field);
  }

  const successForm = computed(() => {
    name.value = validate(name.value);
    lastname.value = validate(lastname.value);
    cellPhone.value = validate(cellPhone.value);
    phone.value = validate(phone.value);
    return !(name.value.isSuccess && lastname.value.isSuccess && cellPhone.value.isSuccess && phone.value.isSuccess);
  });

  return {
    name,
    lastname,
    cellPhone,
    phone,
    email,
    successForm,
  };
}
