
import {defineComponent, onErrorCaptured, Ref, ref} from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "Layout",
  components: {
    // Header,
    Footer,
  },

  setup() {
    const entityImage: Ref<string> = ref("")

    function changeImage(image: string) {
      entityImage.value = image;
    }

    return {
      entityImage,
      changeImage,
    }
  },
});
