import { ref, Ref } from "vue";
import { useRouter } from "vue-router";


export function mainMenuActions() {

    const router = useRouter();

    async function entityOption() {
        await router.push("/administrator/entity");
    }

    async function statisticsOption() {
        await router.push("/administrator/statistics");
    }

    async function parametersOption() {
        await router.push("/administrator/parameters");
    }    


    return {
        entityOption,
        statisticsOption,
        parametersOption,
    };
}
