import { inject, onMounted, ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { userStore } from "@/store/userStore";
import {
  CreateEntityAnalysisRequest,
  managementEntityAnalysisService,
} from "@/services/entity/api/managementUploadSampleService";
import { generateUUID } from "@/services/commons/uuid/uuid";
import { sessionStore } from "@/store/sessionStore";
import {managementUserService, UpdateUserRequest} from "@/services/administrator/api/managementUserService";

export function profileActions() {
  const router = useRouter();
  const showSuccessful: Ref<boolean> = ref(false);
  const { user, setUserUpdated } = userStore();
  const { getEmail } = sessionStore();

  function showSuccessfulModal(): void {
    showSuccessful.value = true;
  }

  function closeSuccessfulModal(): void {
    showSuccessful.value = false;
  }

  async function updateUser(
      id: string,
      firstName: string,
      lastName: string,
      profile: string,
      mobilePhone: string,
      localPhone: string,
  ): Promise<void> {
    const updateUserRequest: UpdateUserRequest = {
      id: id,
      firstName: firstName,
      lastName: lastName,
      profile: profile,
      mobilePhone: mobilePhone,
      localPhone: localPhone,
    };
    await managementUserService().updateUser(updateUserRequest, userStore().getToken());
    await setUserUpdated(firstName, lastName, mobilePhone, localPhone);
    showSuccessfulModal();
  }

  function getEntityImage(): string {
    return userStore().getEntityImage();
  }

  return {
    showSuccessful,
    closeSuccessfulModal,
    user,
    getEmail,
    updateUser,
    getEntityImage,
  };
}
