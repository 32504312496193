
import { computed, defineComponent, ref, Ref } from "vue";
import CreateEditEntity from "@/modals/CreateEditEntity.vue";
import CreateEntityUploadFile from "@/modals/CreateEntityUploadFile.vue";
import { baseEntityActions } from "@/uses/administrator/managementBaseEntityActions";
import Paginator from "@/components/Paginator.vue";
import { paginator } from "@/uses/administrator/paginator";
import ConfirmationModal from "@/modals/ConfirmationModal.vue";
import SuccessfulModal from "@/modals/SuccessfulModal.vue";
import Spinner from "@/components/Spinner.vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "Entity",
  components: {
    CreateEditEntity,
    CreateEntityUploadFile,
    SuccessfulModal,
    Paginator,
    ConfirmationModal,
    Spinner,
    Header,
  },
  setup() {
    const showCreateEditEntityModal: Ref<boolean> = ref(false);
    const showCreateEntityUploadFileModal: Ref<boolean> = ref(false);
    const {
      entities,
      modalCreateEditEntity,
      showCreateEditEntity,
      closeCreateEditEntity,
      actionLabel,
      successfulModal,
      showSuccessfulModal,
      closeSuccessfulModal,
      messageSuccess,
      selectedEntity,
      selectedItemEdit,
      deleteEntityProcess,
      modalConfirmation,
      closeConfirmation,
      showUploadFileEntities,
      modalCreateEntityUploadFile,
      closeUploadEntitiesFile,
      manageUsers,
      logoPath,
    } = baseEntityActions();

    const {
      isFirstPage,
      isLastPage,
      nextPage,
      previousPage,
      returnData,
      maxItems,
    } = paginator();

    maxItems.value = 7;

    const showSpinner : Ref<boolean> = ref(false)

    const paginateEntities = computed(() => {
      return returnData(entities.value);
    });

    return {
      showCreateEditEntityModal,
      showCreateEntityUploadFileModal,
      paginateEntities,
      isFirstPage,
      isLastPage,
      nextPage,
      previousPage,
      modalCreateEditEntity,
      showCreateEditEntity,
      closeCreateEditEntity,
      manageUsers,
      modalConfirmation,
      closeConfirmation,
      successfulModal,
      showSuccessfulModal,
      closeSuccessfulModal,
      actionLabel,
      messageSuccess,
      selectedEntity,
      selectedItemEdit,
      deleteEntityProcess,
      showUploadFileEntities,
      modalCreateEntityUploadFile,
      closeUploadEntitiesFile,
      logoPath,
      showSpinner,
    };
  },
});
