import { onMounted, ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { sessionStore } from "@/store/sessionStore";
import {
  LoginRequest,
  managementLoginService,
} from "@/services/authentication/api/managementLoginService";
import {
  managementPasswordService,
  ForgotPasswordRequest,
} from "@/services/authentication/api/managementPasswordService";
import { timeStore } from "@/store/timeStore";
import {userStore} from "@/store/userStore";

export function loginActions() {
  const router = useRouter();
  const showPassword: Ref<boolean> = ref(false);
  const buttonText: Ref<string> = ref("Siguiente");
  const domainRoute: Ref<string> = ref("");
  const { setInitialDate } = timeStore();

  onMounted(async () => {
    domainRoute.value = (await router.currentRoute.value.meta
      .domainRoute) as string;
  });

  async function preLogin(email: string): Promise<void> {
    const response = await managementLoginService().preLogin(email);
    await sessionStore().setEmail(email);
    switch (response.state) {
      case "COMPLETE": {
        showPassword.value = true;
        buttonText.value = "Iniciar sesión";
        break;
      }
      case "PENDING_MFA": {
        showPassword.value = true;
        buttonText.value = "Iniciar sesión";
        break;
      }
      case "UNCONFIRMED": {
        await router.push(domainRoute.value.concat("confirm-code"));
        break;
      }
      case "PRE_REGISTER": {
        await sessionStore().setEmail(email);
        await router.push(domainRoute.value.concat("register-password"));
        break;
      }
    }
  }

  async function login(
    email: string,
    password: string,
    type: number // 1: Admin - 2: Entity User
  ): Promise<void> {
    const userAuthenticate: LoginRequest = {
      email: email,
      password: password,
      type: type,
    };
    const response = await managementLoginService().login(userAuthenticate);
    await sessionStore().setEmail(email);
    await sessionStore().setSession(response.session);
    await sessionStore().setSecretCode(response.secretCode);
    switch (response.type) {
      case "MFA_SETUP": {
        await router.push(domainRoute.value.concat("authentication-factor"));
        break;
      }
      case "SOFTWARE_TOKEN_MFA": {
        await router.push(domainRoute.value.concat("code-mfa"));
        break;
      }
    }
  }

  async function forgotPassword(email: string): Promise<void> {
    await sessionStore().setEmail(email);
    showPassword.value = false;
    const forgotPasswordRequest: ForgotPasswordRequest = {
      email: email,
    };
    await managementPasswordService().forgotPassword(forgotPasswordRequest);
    setInitialDate(new Date());
    await router.push(domainRoute.value.concat("recover-password"));
  }

  function returnEmail(): void {
    showPassword.value = false;
  }

  return {
    preLogin,
    login,
    returnEmail,
    forgotPassword,
    showPassword,
    buttonText,
  };
}
