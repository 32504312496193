import {
    create_entities_by_file,
    general_entity,
    get_icon
} from "@/services/administrator/api/adminUrls";
import { RestResponse } from "@/services/commons/rest/RestResponse";
import { sendRequest } from "@/services/commons/rest/restClient";

export interface EntityResponse {
    sigepCode: string,
    name: string,
    icon: string,
    id: string,
    type: string,
    sector: string,
    amountAnalysis: number,
}

export interface CreateEntityRequest {
    id: string,
    name: string,
    type: string,
    sector: string,
    sigepCode: string | null,
    icon: any | null,
}

export interface UpdateEntityRequest {
    id: string,
    name: string,
    type: string,
    sector: string,
    icon: any | null,
}

export interface CreateEntitiesRequest {
    file: any
}

export function managementEntityService() {
    async function createEntity(request: CreateEntityRequest, accessToken: string): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(general_entity);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "multipart/form-data"
        };
        const formData = convertFormData(request);
        const response: RestResponse = await sendRequest(url, "POST", formData, jsonHeader, true);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else if (response.statusCode == 409) {
                throw {
                    message: "El entidad ya existe.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
    }

    async function createEntitiesByFile(request: CreateEntitiesRequest, accessToken: string): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(create_entities_by_file);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "multipart/form-data"
        };
        const formData = convertFormData(request);
        const response: RestResponse = await sendRequest(url, "POST", formData, jsonHeader, true);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else {
                const message = JSON.parse(JSON.stringify(response.content)).error;
                throw {
                    message: message,
                    code: response.statusCode
                }
            }
        }
    }

    async function updateEntity(request: UpdateEntityRequest, accessToken: string): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(general_entity);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "multipart/form-data"
        };

        const formData = convertFormData(request);
        const response: RestResponse = await sendRequest(url, "PUT", formData, jsonHeader, true);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else if (response.statusCode == 404) {
                throw {
                    message: "El entidad no existe.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
    }

    async function allEntities(accessToken: string): Promise<EntityResponse[]> {
        const url = process.env.VUE_APP_BASE_URL.concat(general_entity);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "GET", null, jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
        return JSON.parse(JSON.stringify(response.content));
    }

    async function findEntity(accessToken: string, entityId: string): Promise<EntityResponse> {
        const url = process.env.VUE_APP_BASE_URL.concat(general_entity).concat(entityId);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "GET", null, jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else if (response.statusCode == 404) {
                throw {
                    message: "El entidad no existe.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
        return JSON.parse(JSON.stringify(response.content));
    }

    async function deleteEntity(accessToken: string, entityId: string): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(general_entity).concat(entityId);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "DELETE", null, jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else if (response.statusCode == 404) {
                throw {
                    message: "El entidad no existe.",
                    code: response.statusCode
                }
            }
            else {
                const message = JSON.parse(JSON.stringify(response.content)).error;
                throw {
                    message: message,
                    code: response.statusCode
                }
            }
        }
    }

    async function getIcon(accessToken: string, entityId: string): Promise<string> {
        const url = process.env.VUE_APP_BASE_URL.concat(get_icon).concat(entityId);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "GET", null, jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else if (response.statusCode == 404) {
                throw {
                    message: "El entidad no existe.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
        return JSON.parse(JSON.stringify(response.content));
    }

    function convertFormData(request: any): FormData {
        let property: keyof typeof request;
        const formData = new FormData();
        for (property in request) {
            formData.append(property, request[property])
        }
        return formData;
    }

    return {
        allEntities,
        createEntity,
        updateEntity,
        deleteEntity,
        createEntitiesByFile,
    }
}