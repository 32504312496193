import {
    upload_sample
} from "@/services/entity/api/entityUrls";
import { RestResponse } from "@/services/commons/rest/RestResponse";
import { sendRequest } from "@/services/commons/rest/restClient";
export interface CreateEntityAnalysisRequest {
    id: string,
    type: number,
    incidentNumber: string,
    sampleContext: string,
    url: string | null,
    file: any | null,
    entityId: string,
    userId: string,
}

export function managementEntityAnalysisService() {

    async function createEntityAnalysis(request: CreateEntityAnalysisRequest, accessToken: string): Promise<void> {
        const url = process.env.VUE_APP_BASE_URL.concat(upload_sample);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "multipart/form-data"
        };
        const formData = convertFormData(request);
        const response: RestResponse = await sendRequest(url, "POST", formData, jsonHeader, true);
        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else if (response.statusCode == 404) {
                throw {
                    message: "El Análisis ya existe.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: JSON.parse(JSON.stringify(response.content)).error,
                    code: response.statusCode
                }
            }
        }
    }

    function convertFormData(request: any): FormData {
        let property: keyof typeof request;
        const formData = new FormData();
        for (property in request) {
            formData.append(property, request[property])
        }
        return formData;
    }

    return {
        createEntityAnalysis,
    }
}

