import { useRouter } from "vue-router";
import { sessionStore } from "@/store/sessionStore";
import {
    ForgotPasswordRequest,
    managementPasswordService, ResetPasswordRequest
} from "@/services/authentication/api/managementPasswordService";
import {onMounted, ref, Ref} from "vue";
import {timeStore} from "@/store/timeStore";

export function resetPasswordAction() {

    const router = useRouter();
    const domainRoute: Ref<string> = ref("");
    const { getExigencyTime, isTimeExpired, setInitialDate, resetDate } =
        timeStore();
    const showSuccessfulUploadModal: Ref<boolean> = ref(false);
    const messageSuccessfulModal: Ref<string> = ref("");
    const operation: Ref<number> = ref(1) //1- confirmEmail 2- ResendCode
    const tries: Ref<number> = ref(0);

    onMounted(async () => {
        domainRoute.value = await router.currentRoute.value.meta.domainRoute as string
    });

    async function resetPassword(
        code: string,
        password: string
    ): Promise<void> {
        const resetPasswordRequest: ResetPasswordRequest = {
            email: sessionStore().getEmail(),
            code: code,
            newPassword: password
        };

        const error = await managementPasswordService().resetPassword(resetPasswordRequest);
        if (error) {
            tries.value = tries.value + 1;
            if (tries.value >= 3) {
                tries.value = 0;
                await router.push(domainRoute.value.concat("login"));
            }
            throw new Error("Los datos ingresados son incorrectos.");
        }
        else {
            resetDate();
            operation.value = 1;
            showSuccessfulModal();
        }
    }

    async function resendCode(): Promise<void> {
        if (!isTimeExpired(new Date())) {
            throw new Error(`No es posible solicitar un nuevo código antes de ${getExigencyTime()} minuto(s)`);
        }

        const resendConfirmationCodeRequest: ForgotPasswordRequest = {
            email: sessionStore().getEmail(),
        };
        await managementPasswordService().forgotPassword(
            resendConfirmationCodeRequest
        );
        operation.value = 2;
        showSuccessfulModal();
        setInitialDate(new Date());
    }

    function showSuccessfulModal(): void {
        messageSuccessfulModal.value =
            operation.value == 1
                ? "Su contraseña ha sido modificada correctamente"
                : "Se ha enviado un código a su correo, por favor verificarlo e ingresarlo";
        showSuccessfulUploadModal.value = true;
    }

    async function closeSuccessfulModal(): Promise<void> {
        showSuccessfulUploadModal.value = false;
        if (operation.value == 1) {
            await router.push(domainRoute.value.concat("login"));
        }
    }

    async function returnLogin() {;
        await router.push(domainRoute.value.concat("login"));
      }      

    return {
        resetPassword,
        showSuccessfulUploadModal,
        closeSuccessfulModal,
        resendCode,
        returnLogin,
        messageSuccessfulModal,
    };
}