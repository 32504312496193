import {
    find_sigep,
    upload_sample
} from "@/services/user/api/userUrls";
import { RestResponse } from "@/services/commons/rest/RestResponse";
import { sendRequest } from "@/services/commons/rest/restClient";
export interface EntityInfoResponse {
    code: string,
    nombre: string,
    orden: string,
    clasificacion_organica: string,
    tipo_institucion: string | null,
    sector: string,
    municipio: string,
    departamento: string,
}

export function managementSigepCode() {

    async function getEntitybySigep(code: string): Promise<EntityInfoResponse> {
        const url = process.env.VUE_APP_BASE_URL.concat(find_sigep).concat(code);
        const response: RestResponse = await sendRequest(url, "GET", null, null);
        if (!response.isSuccess) {
            if (response.statusCode === 404) {
                throw {
                    message: `La entidad con el código ${code} no ha sido encontrada. Por favor llene los datos de la entidad manualmente`,
                    code: response.statusCode
                }
            }

            throw {
                message: response.content,
                code: response.statusCode
            }
        }

        return JSON.parse(JSON.stringify(response.content));
    }

    return {
        getEntitybySigep,
    }
}

