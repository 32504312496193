import Layout from "@/views/general/Layout.vue";
import UploadSample from "@/views/user/UploadSample.vue";
import Profile from "@/views/user/Profile.vue";
import ChangePassword from "@/views/user/ChangePassword.vue";
import { isAuthenticate } from "@/router/isAuthenticate";

export const userRoutes = [
  {
    path: "/user/",
    component: Layout,
    children: [
      {
        path: "upload-sample",
        component: UploadSample,
        beforeEnter: isAuthenticate,
        meta: {
          domainRoute: "/user/",
        },
      },
      {
        path: "profile",
        component: Profile,
        beforeEnter: isAuthenticate,
        meta: {
          domainRoute: "/user/",
        },
      },
      {
        path: "change-password",
        component: ChangePassword,
        beforeEnter: isAuthenticate,
        meta: {
          domainRoute: "/user/",
        },
      },
    ],
  },
];