import { ContractRule } from "@/services/commons/validator/validatorsTypes";

export class PasswordRule implements ContractRule {
  validationMessage: string;

  constructor(validationMessage: string) {
    this.validationMessage = validationMessage;
  }

  check(value: string, otherValue?: string): boolean {
    const regex = new RegExp(
      /^(?=.*?[A-Za-z])(?=(.*[A-Za-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,15}$/
    );
    return regex.test(value);
  }
}
