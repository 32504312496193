import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { NumericRule } from "@/services/commons/validator/rules/NumericRule";
import { validator } from "@/services/commons/validator/validator";

export function parameterValidator() {
  const name: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El nombre del parámetro es obligatorio"),
    ],
  });

  const value: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El valor del parámetro es obligatorio"),
    ],
  });

  watch(
    () => name.value.val,
    () => {
      name.value.enabled = true;
    }
  );

  watch(
    () => value.value.val,
    () => {
      value.value.enabled = true;
    }
  );

  function validate(field: GenericField): GenericField {
    return validator().validateField(field);
  }

  const successForm = computed(() => {
    name.value = validate(name.value);
    value.value = validate(value.value);
    return !(name.value.isSuccess && value.value.isSuccess);
  });

  return {
    name,
    value,
    successForm,
  };
}
