import { createRouter, createWebHistory, RouteLocationNormalized, NavigationGuardNext } from "vue-router";

import { administratorRoutes } from "@/router/administratorRoutes";
import { authRoutes } from "@/router/authRoutes";
import { userRoutes } from "@/router/userRoutes";
import { externalUserRoutes } from "@/router/externalUserRoutes";
import {encryptStorage} from "@/services/commons/security/Encrypt";
import {userStore} from "@/store/userStore";
import {sessionStore} from "@/store/sessionStore";

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: "/external-user/upload-sample"
  }
];

export const finalRoutes = [
  ...userRoutes,
  ...administratorRoutes,
  ...authRoutes,
  ...externalUserRoutes,
  ...routes,
];

const isProtectedRoute = (route: string) => {
  const isUserProtected = userRoutes[0].children.some(c => route.includes(c.path));
  const isAdministratorProtected = administratorRoutes[0].children.some(c => route.includes(c.path));
  return isUserProtected || isAdministratorProtected;
}


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: finalRoutes,
});

router.beforeEach((
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
  if (from.path == "/" && !to.path.includes("login")) {
    if(isProtectedRoute(to.path)) {
      userStore().loadUserMemory();
      sessionStore().loadSessionMemory();
      next()
    }
    next("/external-user/upload-sample");
  }
  next();
});

export default router;
