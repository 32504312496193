import { ContractRule } from "@/services/commons/validator/validatorsTypes";

export class EqualsRule implements ContractRule {
  validationMessage: string;

  constructor(validationMessage: string) {
    this.validationMessage = validationMessage;
  }

  check(value: string, otherValue?: string): boolean {
    return value === otherValue;
  }
}
