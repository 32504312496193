import { ContractRule } from "@/services/commons/validator/validatorsTypes";

export class EmailRule implements ContractRule {
  validationMessage: string;

  constructor(validationMessage: string) {
    this.validationMessage = validationMessage;
  }

  check(value: string, otherValue?: string): boolean {
    const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return regex.test(value);
  }
}
