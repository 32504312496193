
import { computed, defineComponent, ref, Ref, watch } from "vue";
import { parameterValidator } from "@/services/administrator/administratorValidators/parameterValidator";
import { managementParametersActions } from "@/uses/administrator/managementParametersActions";
import {UpdateParameter} from "@/services/administrator/api/managementParametersService";

export default defineComponent({
  name: "CreateEditParameter",
  props: {
    actionLabel: {
      type: String,
      default: "EDITAR",
    },
    parameter: {
      type: Object as () => UpdateParameter,
      required: true,
    }
  },
  components: {},
  setup(props, context) {

    const { name, value, successForm } =
      parameterValidator();

    watch(
        () => props.parameter,
        () => {
          name.value.val = props.parameter.key,
          value.value.val = props.parameter.value
        }
    );

    const { goToParameters, updateParameter } = managementParametersActions();

    async function closeModal() {
      await goToParameters();
      context.emit("close", false);
    }    

    async function createEditParameterTransaction() {
      try {
        await updateParameter(name.value.val ?? "", value.value.val ?? "")
        resetValidator();
        context.emit("close", true);
      } catch (e) {
        resetValidator();
        context.emit("close", false);
        throw e;
      }
    }         

    function resetValidator() {
    }    

    return {
      name,
      value,
      successForm,  
      closeModal,
      createEditParameterTransaction,
    };
  },
});
