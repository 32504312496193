
import { computed, defineComponent, ref, Ref } from "vue";
import { mainMenuActions } from "@/uses/administrator/mainMenuActions";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "MainMenu",
  components: {
    Header,
  },
  setup() {
    const { entityOption, statisticsOption, parametersOption, } = mainMenuActions();

    return {
      entityOption,
      statisticsOption,
      parametersOption,
    };
  },
});
