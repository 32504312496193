import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {userStore} from "@/store/userStore";

export const isAuthenticate = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (userStore().isAuthenticated()) {
    next();
  }

  const domainRoute = from.meta.domainRoute as string;
  domainRoute != "" && domainRoute != null && domainRoute != undefined
    ? next(domainRoute.concat("login"))
    : next("/user/login");
};
