
import {computed, defineComponent, onMounted, ref, Ref} from "vue";
import { changePasswordValidator } from "@/services/entity/entityValidators/changePasswordValidator";
import SuccessfulModal from "@/modals/SuccessfulModal.vue";
import { changePasswordAction } from "@/uses/authentication/managementChangePasswordActions";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "ChangePassword",
  components: {
    SuccessfulModal,
    Header,
  },
  setup(props, context) {
    const image: Ref<string> = ref("")
    const { currentPassword, newPassword, confirmPassword, successForm } =
      changePasswordValidator();

    const { changePassword, closeSuccessfulModal, showSuccessfulUploadModal, getEntityImage } =
      changePasswordAction();

    onMounted(() => {
      image.value = getEntityImage();
    })

    return {
      currentPassword,
      newPassword,
      confirmPassword,
      successForm,
      showSuccessfulUploadModal,
      closeSuccessfulModal,
      changePassword,
      image,
    };
  },
});
