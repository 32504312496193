import { inject, ref, Ref } from "vue";
import {
  CreateUserAnalysisRequest,
  managementUserAnalysisService,
} from "@/services/user/api/managementUploadSampleService";
import { generateUUID } from "@/services/commons/uuid/uuid";
import { useRouter } from "vue-router";
import {EntityInfoResponse, managementSigepCode} from "@/services/user/api/managementSigepCode";

export function uploadSampleActions() {
  const urlOption: Ref<boolean> = ref(true);
  const fileOption: Ref<boolean> = ref(false);
  const showIconFile: Ref<boolean> = ref(false);
  const disabledUrlBox: Ref<boolean> = ref(false);
  const termsAndConditions: Ref<boolean> = ref(false);
  const modalTermsAndConditions: Ref<boolean> = ref(false);
  const validateCaptcha: Ref<boolean> = ref(false);
  const captchaKey: Ref<string> = inject("captchaKey", ref(""));
  const entitySigepInfo: Ref<EntityInfoResponse | null> = ref(null);
  const showDetail: Ref<boolean> = ref(false);
  const allowsNews: Ref<boolean> = ref(false);
  const router = useRouter();

  function selectSampleOption() {
      urlOption.value = !urlOption.value;
      fileOption.value = !fileOption.value;

    if(urlOption.value) {
      fileOption.value = false;
      showIconFile.value = false;
      disabledUrlBox.value = false;
    } else {
      fileOption.value = true;
      showIconFile.value = true;
      disabledUrlBox.value = true;
    }
  }

  function showTermsAndConditions() {
    modalTermsAndConditions.value = true;
  }

  function closeTermsAndConditions() {
    modalTermsAndConditions.value = false;
  }

  function showEntitySigepInfo(show: boolean) : void {
    showDetail.value = show
  }

  async function detectSigep(code: string) : Promise<void> {
    entitySigepInfo.value = await managementSigepCode().getEntitybySigep(code);
    entitySigepInfo.value.code = code;
    showEntitySigepInfo(true);
  }

  async function uploadSample(
    userProfile: string,
    organizationType: string | null,
    sector: string | null,
    companyName: string | null,
    sigepCode: string | null,
    firstName: string,
    lastName: string,
    phoneNumber: number | null,
    email: string,
    url: string | null,
    file: any | null,
  ): Promise<void> {
    const type: Ref<number> = ref(1);
    type.value = url != null ? 2 : 1;
    const createUserAnalysisRequest: CreateUserAnalysisRequest = {
      id: generateUUID(),
      type: type.value,
      userProfile: userProfile,
      organizationType: organizationType,
      sector: sector,
      companyName: companyName,
      sigepCode: sigepCode,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      url: url,
      file: file,
      allowsNews: allowsNews.value,
    };
    await managementUserAnalysisService().createUserAnalysis(
      createUserAnalysisRequest
    );
  }

  function verifyCaptcha(response: string): void {
    validateCaptcha.value = (response != "" && response.length > 0) ? true : false;
  }

  function expiredCaptcha(): void {
    validateCaptcha.value = false
  }

  return {
    urlOption,
    fileOption,
    selectSampleOption,
    showIconFile,
    disabledUrlBox,
    uploadSample,
    termsAndConditions,
    modalTermsAndConditions,
    showTermsAndConditions,
    closeTermsAndConditions,
    validateCaptcha,
    verifyCaptcha,
    captchaKey,
    expiredCaptcha,
    detectSigep,
    entitySigepInfo,
    showEntitySigepInfo,
    showDetail,
    allowsNews,
  };
}
