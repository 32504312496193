const admin_user_base = "/User";
const admin_entity_base = "/Entity";
const admin_statistics_base = "/EntityAnalysis";
const admin_statistics_sample_base = "/Sample";
export const general_user = admin_user_base.concat("/");
export const general_entity = admin_entity_base.concat("/");
export const general_statistics = admin_statistics_base.concat("/");
export const general_statistics_sample = admin_statistics_sample_base.concat("/");
export const find_by_entity = admin_user_base.concat("/FindByEntity/");
export const get_icon = admin_entity_base.concat("/GetIcon/");
export const top5_users = admin_user_base.concat("/FindTop5");
export const top5_entities = admin_entity_base.concat("/FindTop5");
export const create_entities_by_file = admin_entity_base.concat("/CreateEntitiesByFile");
export const parameters = "/Parameter/"