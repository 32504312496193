
import { computed, defineComponent, ref, Ref } from "vue";
import { confirmCodeValidator } from "@/services/authentication/authenticationValidators/confirmCodeValidator";
import { confirmLoginActions } from "@/uses/authentication/managementConfirmLoginActions";
import { confirmEmailActions } from "@/uses/authentication/managementConfirmEmailActions";
import SuccessfulModal from "@/modals/SuccessfulModal.vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "ConfirmCode",
  components: {
    SuccessfulModal,
    Header
  },
  setup() {
    const { code, successForm } = confirmCodeValidator();
    const {
      confirmEmail,
      resendCode,
      closeSuccessfulModal,
      showSuccessfulUploadModal,
      messageSuccessfulModal,
      returnLogin,
      emailTest
    } = confirmEmailActions();

    return {
      code,
      successForm,
      confirmEmail,
      resendCode,
      closeSuccessfulModal,
      showSuccessfulUploadModal,
      messageSuccessfulModal,
      returnLogin,
      emailTest
    };
  },
});
