const authorization_base = "/User";
export const pre_login = authorization_base.concat("/PreLogin/");
export const login_url = authorization_base.concat("/Login");
export const create_cognito = authorization_base.concat("/Cognito");
export const confirm_email = authorization_base.concat("/ConfirmEmail");
export const resent_confirmation_code = authorization_base.concat("/ResendConfirmationCode");
export const verify_mfa = authorization_base.concat("/VerifyMFA");
export const confirm_login = authorization_base.concat("/ConfirmLogin");
export const change_password = authorization_base.concat("/ChangePassword");
export const forgot_password = authorization_base.concat("/ForgotPassword");
export const reset_password = authorization_base.concat("/ResetPassword");
export const get_user = authorization_base.concat("/FindByToken");
