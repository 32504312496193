
import { computed, defineComponent, ref, Ref } from "vue";
import { recoverPasswordValidator } from "@/services/authentication/authenticationValidators/recoverPasswordValidator";
import { resetPasswordAction } from "@/uses/authentication/managementResetPasswordActions";
import SuccessfulModal from "@/modals/SuccessfulModal.vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "RecoverPassword",
  components: {
    SuccessfulModal,
    Header
  },
  setup() {
    const { code, password, confirmPassword, successForm } =
      recoverPasswordValidator();
    const {
      resetPassword,
      showSuccessfulUploadModal,
      closeSuccessfulModal,
      resendCode,
      returnLogin,
      messageSuccessfulModal,
    } = resetPasswordAction();

    return {
      code,
      password,
      confirmPassword,
      successForm,
      resetPassword,
      showSuccessfulUploadModal,
      closeSuccessfulModal,
      resendCode,
      returnLogin,
      messageSuccessfulModal,
    };
  },
});
