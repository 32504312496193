
import { computed, defineComponent, ref } from "vue";
import { BarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default defineComponent({
  name: "GraphicEntity",
  components: { BarChart },
  props: {
    labels: {
      type: Array as () => string[],
    },
    data: {
      type: Array as () => number[],
    },
  },
  setup(props) {
    const barRef = ref();

    const dataGraph = computed(() => ({
      labels: props.labels,
      datasets: [
        {
          backgroundColor: [
            "#3366CC",
            "#9DBEFF",
            "#3366CC",
            "#9DBEFF",
            "#3366CC",
            "#9DBEFF",
          ],
          data: props.data,
          borderRadius: 15,
          barThickness: 70,
        },
      ],
    }));

    const options = ref({
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
      layout: {
        padding: 30,
      },
    });

    return {
      dataGraph,
      barRef,
      options,
    };
  },
});
