
import {computed, defineComponent, ref, Ref, watch} from "vue";
import { uploadSampleActions } from "@/uses/user/uploadSampleActions";
import { uploadSampleProfilePublicValidator } from "@/services/user/externalUserValidators/uploadSampleProfilePublicValidator";
import { uploadUrlValidator } from "@/services/user/externalUserValidators/uploadUrlValidator";
import { uploadFileValidator } from "@/services/user/externalUserValidators/uploadFileValidator";
import TermsAndConditions from "@/modals/TermsAndConditions.vue";
import { VueRecaptcha } from "vue-recaptcha";
import Spinner from "@/components/Spinner.vue";
import DetailsEntitySigepModal from "@/modals/DetailsEntitySigepModal.vue";

export default defineComponent({
  name: "FormUploadSampleProfilePublic",
  components: {
    TermsAndConditions,
    VueRecaptcha,
    Spinner,
    DetailsEntitySigepModal,
  },
  props: {
    profile: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props, context) {
    const {
      urlOption,
      fileOption,
      selectSampleOption,
      showIconFile,
      termsAndConditions,
      showTermsAndConditions,
      modalTermsAndConditions,
      closeTermsAndConditions,
      disabledUrlBox,
      uploadSample,
      validateCaptcha,
      verifyCaptcha,
      captchaKey,
      expiredCaptcha,
      detectSigep,
      entitySigepInfo,
      showEntitySigepInfo,
      showDetail,
      allowsNews,
    } = uploadSampleActions();
    const {
      organizationType,
      code,
      entityName,
      name,
      lastname,
      phoneNumber,
      email,
      confirmEmail,
      successForm,
    } = uploadSampleProfilePublicValidator();
    const { successURL, url } = uploadUrlValidator();
    const { successFile, fileName } = uploadFileValidator();
    const fileSample = ref(null);
    const file = ref(null);
    const showSpinner : Ref<boolean> = ref(false)
    const isSigepShowed: Ref<boolean> = ref(true)

    const fullValidator = computed(() => {
      const initialValidation =
        successForm.value ||
        !termsAndConditions.value
        || !validateCaptcha.value;
      return urlOption.value
        ? initialValidation || successURL.value
        : initialValidation || successFile.value;
    });

    function loadFile(event: any) {
      const fileUploaded = event.target.files[0];
      fileName.value.val = fileUploaded.name;
      file.value = fileUploaded;
      url.value.val = null;
    }

    async function uploadSampleTransaction() {
      showSpinner.value = true;
      await uploadSample(
        props.profile,
        organizationType.value.val,
        null,
        entityName.value.val || "",
        code.value.val,
        name.value.val || "",
        lastname.value.val || "",
        null,
        email.value.val || "",
        url.value.val,
        file.value
      );
      await new Promise(r => setTimeout(r, 700));
      showSpinner.value = false;
      context.emit("success");
    }

    function showSigep(event: any) : void {
      isSigepShowed.value = !(event.target.value === "Empresa privada")
      code.value.val = event.target.value === "Empresa privada" ? null : code.value.val;
    }

    async function detectSigepChange(event: any) {
      const target = event.target.value;
      if (target.length > 0) {
        showSpinner.value = true;
        try {
          await detectSigep(event.target.value);
          entityName.value.val = entitySigepInfo.value?.nombre || null;
          await new Promise(r => setTimeout(r, 700));
        }
        catch (e) {
          throw e;
        }
        finally {
          showSpinner.value = false;
        }
      }
    }

    watch(
        () => urlOption.value,
        () => {
          url.value.val = urlOption.value ? url.value.val : "";
        }
    );

    watch(
        () => fileOption.value,
        () => {
          fileName.value.val = fileOption.value ? fileName.value.val : "";
          file.value = null;
        }
    );

    return {
      fileSample,
      loadFile,
      urlOption,
      fileOption,
      selectSampleOption,
      successFile,
      showIconFile,
      disabledUrlBox,
      organizationType,
      code,
      entityName,
      name,
      lastname,
      phoneNumber,
      email,
      url,
      fileName,
      confirmEmail,
      successForm,
      successURL,
      uploadSampleTransaction,
      fullValidator,
      termsAndConditions,
      showTermsAndConditions,
      modalTermsAndConditions,
      closeTermsAndConditions,
      verifyCaptcha,
      captchaKey,
      expiredCaptcha,
      showSpinner,
      showSigep,
      isSigepShowed,
      detectSigepChange,
      entitySigepInfo,
      showEntitySigepInfo,
      showDetail,
      allowsNews,
    };
  },
});
