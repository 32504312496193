
import { defineComponent, watch } from "vue";
import { baseSampleDetailActions } from "@/uses/administrator/managementBaseSampleDetailActions";
import {EntityAnalysisResponse} from "@/services/administrator/api/managementAdministratorService";
import {EntityInfoResponse} from "@/services/user/api/managementSigepCode";

export default defineComponent({
  name: "DetailsEntitySigepModal",
  props: {
    entityInfo: {
      type: Object as () => EntityInfoResponse,
    },
  },
  setup(props, context) {
    function closeModal() {
      context.emit("close");
    }

    watch(
        () => props.entityInfo,
        () => {
        }
    );

    return {
      closeModal,
    };
  },
});
