import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { EmailRule } from "@/services/commons/validator/rules/EmailRule";
import { EqualsRule } from "@/services/commons/validator/rules/EqualsRule";
import { validator } from "@/services/commons/validator/validator";

export function uploadSampleCitizenshipProfileValidator() {
  const name: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El nombre es obligatorio"),
    ],
  });

  const lastname: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El apellido es obligatorio"),
    ],
  });

  const email: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El correo es obligatorio"),
      new EmailRule("Correo inválido"),
    ],
  });

  const confirmEmail: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("Confirma tu correo"),
      new EmailRule("Correo inválido"),
      new EqualsRule("Los correos no coinciden"),
    ],
  });

  watch(
    () => name.value.val,
    () => {
      name.value.enabled = true;
    }
  );

  watch(
    () => lastname.value.val,
    () => {
      lastname.value.enabled = true;
    }
  );

  watch(
    () => email.value.val,
    () => {
      email.value.enabled = true;
    }
  );

  watch(
    () => confirmEmail.value.val,
    () => {
      confirmEmail.value.enabled = true;
    }
  );

  function validate(field: GenericField, otherValue?: string | null): GenericField {
    return validator().validateField(field, otherValue);
  }

  const successForm = computed(() => {
    name.value = validate(name.value);
    lastname.value = validate(lastname.value);
    email.value = validate(email.value);
    confirmEmail.value = validate(confirmEmail.value, email.value.val);
    return !( name.value.isSuccess && lastname.value.isSuccess && email.value.isSuccess && confirmEmail.value.isSuccess);
  });

  return {
    name,
    lastname,
    email,
    confirmEmail,
    successForm,
  };
}
