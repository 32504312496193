
import { computed, defineComponent, ref, Ref, watch } from "vue";
import Paginator from "@/components/Paginator.vue";
import { paginator } from "@/uses/administrator/paginator";
import CreateEditParameter from "@/modals/CreateEditParameter.vue";
import { baseParametersActions } from "@/uses/administrator/managementBaseParametersActions";
import {userStore} from "@/store/userStore";
import SuccessfulModal from "@/modals/SuccessfulModal.vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "Parameters",
  components: {
    Paginator,
    CreateEditParameter,
    SuccessfulModal,
    Header,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      modalCreateEditParameter,
      showCreateEditParameter,
      actualParameter,
      modifyActualParameter,
      parameters,
      successfulModal,
      messageSuccess,
      closeCreateEditParameter,
      closeSuccessfulModal,
    } = baseParametersActions();

    const {
      isFirstPage,
      isLastPage,
      nextPage,
      previousPage,
      returnData,
      maxItems,
    } = paginator();

    maxItems.value = 7;

    const paginateParameters = computed(() => {
      return returnData(parameters.value);
    });

    return {
      isFirstPage,
      isLastPage,
      nextPage,
      previousPage,
      modalCreateEditParameter,
      showCreateEditParameter,
      actualParameter,
      modifyActualParameter,
      parameters,
      successfulModal,
      messageSuccess,
      closeCreateEditParameter,
      closeSuccessfulModal,
      paginateParameters,
    };
  },
});
