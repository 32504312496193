import { ContractRule } from "@/services/commons/validator/validatorsTypes";

export class ColombiaNumberPhoneRule implements ContractRule {
  validationMessage: string;

  constructor(validationMessage: string) {
    this.validationMessage = validationMessage;
  }

  check(value: string, otherValue?: string): boolean {
    const mobileRegex  = new RegExp(/^(?:\+57|57)?3\d{9}$/);
    const fixedRegex = new RegExp(/^(?:\+57|57)?\d{7}$/);
    return mobileRegex.test(value) || fixedRegex.test(value);
  }
}
