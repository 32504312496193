import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { validator } from "@/services/commons/validator/validator";

export function uploadSampleValidator() {
  const profile: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("El perfil es obligatorio"),
    ],
  });

  watch(
    () => profile.value.val,
    () => {
      profile.value.enabled = true;
    }
  );

  function validate(field: GenericField): GenericField {
    return validator().validateField(field);
  }

  const successForm = computed(() => {
    profile.value = validate(profile.value);
    return !(profile.value.isSuccess);
  });

  return {
    profile,
    successForm,
  };
}
