
import { computed, defineComponent, ref, Ref } from "vue";
import { registerPasswordValidator } from "@/services/authentication/authenticationValidators/registerPasswordValidator";
import { definePasswordAction } from "@/uses/authentication/managementDefinePasswordActions";
import SuccessfulModal from "@/modals/SuccessfulModal.vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "RegisterPassword",
  components: {
    SuccessfulModal,
    Header,
  },
  setup() {
    const { password, confirmPassword, successForm } =
      registerPasswordValidator();
    const { definePassword, closeSuccessfulModal, showSuccessfulUploadModal, returnLogin } =
      definePasswordAction();

    return {
      definePassword,
      password,
      confirmPassword,
      successForm,
      closeSuccessfulModal,
      showSuccessfulUploadModal,
      returnLogin,
    };
  },
});
