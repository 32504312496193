import { userStore } from "@/store/userStore";
import {
    CreateUserRequest,
    managementUserService, UpdateUserRequest
} from "@/services/administrator/api/managementUserService";
import { useRouter } from "vue-router";

export function managementUserActions() {

    const router = useRouter();
    async function createUser(
        firstName: string,
        lastName: string,
        email: string,
        profile: string,
        mobilePhone: string,
        localPhone: string,
        role: number, // 1-> Administrator, 2-> Entity User
        entityId: string,
        entityName: string,
    ): Promise<void> {
        const createUserRequest: CreateUserRequest = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            profile: profile,
            mobilePhone: mobilePhone,
            localPhone: localPhone,
            role: role,
            entityId: entityId,
            entityName: entityName,
        };
        await managementUserService().createUser(createUserRequest, userStore().getToken());
    }

    async function updateUser(
        id: string,
        firstName: string,
        lastName: string,
        profile: string,
        mobilePhone: string,
        localPhone: string,
    ): Promise<void> {
        const updateUserRequest: UpdateUserRequest = {
            id: id,
            firstName: firstName,
            lastName: lastName,
            profile: profile,
            mobilePhone: mobilePhone,
            localPhone: localPhone,
        };
        await managementUserService().updateUser(updateUserRequest, userStore().getToken());
    }

    async function goToUsers(entityId: string, entityName: string) : Promise<void> {
        await router.push("/");
        await router.push("/administrator/users/".concat(entityId).concat("/").concat(entityName));
    }

    return {
        createUser,
        updateUser,
        goToUsers,
    }
}