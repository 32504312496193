
import { computed, defineComponent, ref, Ref } from "vue";

export default defineComponent({
  name: "TermsAndConditions",

  setup(props, context) {
    function closeModal() {
      context.emit("close");
    }
    return {
      closeModal,
    };
  },
});
