
import { computed, defineComponent, ref, Ref } from "vue";
import GraphicEntity from "@/components/GraphicEntity.vue";
import GraphicUser from "@/components/GraphicUser.vue";
import { graphicActions } from "@/uses/administrator/graphicActions";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "Graphics",
  components: {
    GraphicEntity,
    GraphicUser,
    Header,
  },
  setup() {
    const chooseGraphic: Ref<boolean> = ref(true);
    const {
      statisticsOption,
      dataGraphicEntities,
      dataLabelEntities,
      dataGraphicUsers,
      dataLabelUsers,
      changeGraph,
      switchGraph,
    } = graphicActions();

    return {
      chooseGraphic,
      statisticsOption,
      dataGraphicEntities,
      dataLabelEntities,
      dataGraphicUsers,
      dataLabelUsers,
      changeGraph,
      switchGraph,
    };
  },
});
