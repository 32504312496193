
import { computed, defineComponent, ref, Ref } from "vue";

export default defineComponent({
  name: "ConfirmationModal",
  props: {
    message: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props, context) {

    function closeModal(option: boolean) {
      context.emit("close", option);
    }

    return {
      closeModal,

    };
  },
});
