import { userStore } from "@/store/userStore";
import { useRouter } from "vue-router";
import {
    managementParametersService,
    UpdateParameter
} from "@/services/administrator/api/managementParametersService";

export function managementParametersActions() {

    const router = useRouter();

    async function goToParameters() : Promise<void> {
        await router.push("/");
        await router.push("/administrator/parameters/");
    }

    async function updateParameter(key: string, value: string) : Promise<void> {
        const request : UpdateParameter = {
            key: key,
            value: value
        };
        await managementParametersService().updateParameter(request, userStore().getToken());
    }

    return {
        goToParameters,
        updateParameter,
    }
}