import { useRouter } from "vue-router";
import { userStore } from "@/store/userStore";
import {
    ChangePasswordRequest,
    managementPasswordService,
} from "@/services/authentication/api/managementPasswordService";
import {ref, Ref} from "vue";

export function changePasswordAction() {

    const router = useRouter();
    const showSuccessfulUploadModal: Ref<boolean> = ref(false);

    async function changePassword(
        oldPassword: string,
        newPassword: string
    ): Promise<void> {
        const changePasswordRequest: ChangePasswordRequest = {
            oldPassword: oldPassword,
            newPassword: newPassword
        };
        await managementPasswordService().changePassword(changePasswordRequest, userStore().getToken());
        showSuccessfulModal();
    }

    function showSuccessfulModal(): void {
        showSuccessfulUploadModal.value = true;
    }

    async function closeSuccessfulModal(): Promise<void> {
        showSuccessfulUploadModal.value = false;
        await router.push("/");
        await router.push("/user/change-password")
    }

    function getEntityImage(): string {
        return userStore().getEntityImage();
    }

    return {
        changePassword,
        closeSuccessfulModal,
        showSuccessfulUploadModal,
        getEntityImage,
    };
}