import Layout from "@/views/general/Layout.vue";
import UploadSample from "@/views/externalUser/UploadSample.vue";


export const externalUserRoutes = [
  {
    path: "/external-user/",
    component: Layout,
    children: [
      {
        path: "upload-sample",
        component: UploadSample,
      },

    ],
  },
];
