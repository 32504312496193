import { userStore } from "@/store/userStore";
import {
    CreateEntityRequest,
    managementEntityService, UpdateEntityRequest
} from "@/services/administrator/api/managementEntityService";
import { generateUUID } from "@/services/commons/uuid/uuid";
import { useRouter } from "vue-router";
import {EntityInfoResponse, managementSigepCode} from "@/services/user/api/managementSigepCode";
import {ref, Ref} from "vue";

export function managementEntityActions() {

    const entitySigepInfo: Ref<EntityInfoResponse | null> = ref(null);
    const showDetail: Ref<boolean> = ref(false)

    const router = useRouter();
    async function createEntity(
        name: string,
        type: string,
        sector: string,
        sigepCode: string | null,
        icon: any | null,
    ): Promise<void> {
        const createEntityRequest: CreateEntityRequest = {
            id: generateUUID(),
            name: name,
            type: type,
            sector: sector,
            sigepCode: sigepCode,
            icon: icon,
        };
        await managementEntityService().createEntity(createEntityRequest, userStore().getToken());
    }

    async function updateEntity(
        id: string,
        name: string,
        type: string,
        sector: string,
        icon: any | null,
    ): Promise<void> {
        const updateEntityRequest: UpdateEntityRequest = {
            id: id,
            name: name,
            type: type,
            sector: sector,
            icon: icon,
        };
        await managementEntityService().updateEntity(updateEntityRequest, userStore().getToken());
    }

    async function goToEntities() : Promise<void> {
        await router.push("/");
        await router.push("/administrator/entity");
    }

    function showEntitySigepInfo(show: boolean) : void {
        showDetail.value = show
    }

    async function detectSigep(code: string) : Promise<void> {
        entitySigepInfo.value = await managementSigepCode().getEntitybySigep(code);
        showEntitySigepInfo(true);
    }

    return {
        createEntity,
        updateEntity,
        goToEntities,
        detectSigep,
        entitySigepInfo,
        showEntitySigepInfo,
        showDetail,
    }
}