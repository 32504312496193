import {
    top5_users,
    top5_entities, general_statistics, general_statistics_sample,
} from "@/services/administrator/api/adminUrls";
import { RestResponse } from "@/services/commons/rest/RestResponse";
import { sendRequest } from "@/services/commons/rest/restClient";

export interface EntityResponse {
    sigepCode: string,
    name: string,
    icon: string,
    id: string,
    type: string,
    sector: string,
    amountAnalysis: number,
}

export interface EntityAnalysisResponse {
    id: string,
    entityId: string,
    entityName: string,
    userId: string,
    userName: string,
    userEmail: string,
    sampleId: string,
    result: string | null,
    state: string,
    type: string,
    date: string,
    sampleContext: string,
    incidentNumber: string,

}

export interface UserResponse {
    lastName: string,
    firstName: string,
    role: string,
    id: string,
    profile: string,
    entityId: string,
    amountAnalysis: number,
    mobilePhone: string,
    entityName: string,
    name: string,
    state: string,
    email: string,
    localPhone: string,
    userCognitoId: string,
}

export interface SampleResponse {
    id: string,
    fileName: string | null,
    url: string | null,
    type: string,
    state: string,
    hashMD5: string | null,
    size: string | null,
    result: string | null,
    isMalicious: boolean | null,
    response: string | null,
    date: string,
}

export function managementAdministratorService() {

    async function getSample(sampleId: string, accessToken: string): Promise<SampleResponse> {
        const url = process.env.VUE_APP_BASE_URL.concat(general_statistics_sample).concat(sampleId);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "GET", null, jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
        return JSON.parse(JSON.stringify(response.content));
    }

    async function allSamplesEntity(accessToken: string): Promise<EntityAnalysisResponse[]> {
        const url = process.env.VUE_APP_BASE_URL.concat(general_statistics);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "GET", null, jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
        return JSON.parse(JSON.stringify(response.content));
    }
    async function top5Users(accessToken: string): Promise<UserResponse[]> {
        const url = process.env.VUE_APP_BASE_URL.concat(top5_users);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken,
        };
        const response: RestResponse = await sendRequest(url, "GET", null, jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
        return JSON.parse(JSON.stringify(response.content));
    }

    async function top5Entities(accessToken: string): Promise<EntityResponse[]> {
        const url = process.env.VUE_APP_BASE_URL.concat(top5_entities);
        const jsonHeader = {
            "Authorization": "Bearer " + accessToken
        };

        const response: RestResponse = await sendRequest(url, "GET", null, jsonHeader);

        if (!response.isSuccess) {
            if (response.statusCode == 401) {
                throw {
                    message: "La sesión del usuario ha vencido.",
                    code: response.statusCode
                }
            }
            else {
                throw {
                    message: response.content,
                    code: response.statusCode
                }
            }
        }
        return JSON.parse(JSON.stringify(response.content));
    }

    return {
        top5Users,
        top5Entities,
        allSamplesEntity,
        getSample,
    }
}