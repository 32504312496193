import { computed, ref, Ref, watch } from "vue";
import { GenericField } from "@/services/commons/validator/validatorsTypes";
import { RequiredRule } from "@/services/commons/validator/rules/RequiredRule";
import { PasswordRule } from "@/services/commons/validator/rules/PasswordRule";
import { EqualsRule } from "@/services/commons/validator/rules/EqualsRule";
import { validator } from "@/services/commons/validator/validator";

export function changePasswordValidator() {
  const currentPassword: Ref<GenericField> = ref({
    enabled: false,
    errors: [],
    isSuccess: true,
    val: "",
    rules: [
      new RequiredRule("La contraseña actual es obligatoria"),
    ],
  });

  const newPassword: Ref<GenericField> = ref({
    enabled: false,
    val: "",
    errors: [],
    isSuccess: true,
    rules: [
      new RequiredRule("La contraseña es obligatoria"),
      new PasswordRule("La contraseña debe contener como mínimo: 1 mayúscula, 1 minúscula, 1 número, 1 símbolo y 8 caracteres"),
    ],
  });

  const confirmPassword: Ref<GenericField> = ref({
    enabled: false,
    errors: [],
    isSuccess: true,
    val: "",
    rules: [
      new RequiredRule("Confirma tu contraseña"),
      new EqualsRule("Las contraseñas no coinciden"),
    ],
  });

  watch(
    () => currentPassword.value.val,
    () => {
      currentPassword.value.enabled = true;
    }
  );

  watch(
    () => newPassword.value.val,
    () => {
      newPassword.value.enabled = true;
    }
  );

  watch(
    () => confirmPassword.value.val,
    () => {
      confirmPassword.value.enabled = true;
    }
  );

  function validate(field: GenericField, otherValue?: string | null): GenericField {
    return validator().validateField(field, otherValue);
  }

  const successForm = computed(() => {
    currentPassword.value = validate(currentPassword.value);
    newPassword.value = validate(newPassword.value);
    confirmPassword.value = validate(confirmPassword.value, newPassword.value.val);
    return !(currentPassword.value.isSuccess && newPassword.value.isSuccess && confirmPassword.value.isSuccess);
  });

  return {
    currentPassword,
    newPassword,
    confirmPassword,
    successForm,
  };
}
