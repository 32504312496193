import { createApp, ref, Ref } from "vue";
import App from "./App.vue";
import router from "./router";

const app = createApp(App)
const captchaKey: Ref<string> = ref(process.env.VUE_APP_CAPTCHA_KEY)

app.provide("captchaKey", captchaKey)
app.use(router).mount("#app")


